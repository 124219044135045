import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Correct import for jwt-decode
import handwritten from './assets/handwriting.png';
import historys from './assets/history.png';
import qrimage from './assets/qr-code.png';
import searchimage from './assets/search (2).png';
import { FaUpload } from "react-icons/fa6";
import { MdOutlineQrCodeScanner } from "react-icons/md";
import { MdOutlineReceipt } from "react-icons/md";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

export default function Navbar({ install, setcurrentPath }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const [role, setRole] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const decodedToken = jwtDecode(token);
      setRole(decodedToken.role);
      setIsLoggedIn(true);
    }
  }, []);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const setTime = async () => {
    console.log("click");
    if (localStorage.getItem("user_name")) {
      localStorage.removeItem("user_login");
      localStorage.removeItem("user_name");
      localStorage.removeItem("token");
      setRole(null);
      setIsLoggedIn(false);
      navigate("/login");
    }
  };

  const isDoctor = role === 'Doctor';
  const isNonMedical = role === 'NonMedical';

  const isHospital = role === 'Hospital';

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const location = useLocation();
  setcurrentPath(location.pathname);


  return (
    <>
      {/* Navbar for tablet and laptop screens */}
      <div className="hidden md:flex justify-between border ">
        <div className="h-9 flex items-center z-20 py-1">
          {isLoggedIn ? (
            <>
              {isDoctor && (
                <>
                  <Link to="/" className="rounded-lg font-bold w-[90px] text-center text-blue-500 hover:text-blue-700 ml-1 transition-all 1s hover:bg-slate-200 text-xl">
                    <img
                      src={qrimage}
                      alt="QR Code"
                      className="h-8 w-8 ml-4 my-1"
                    />
                  </Link>
                  <Link to="/handwritten" className="rounded-lg font-bold w-[90px] text-center text-blue-500 hover:text-blue-700 transition-all 1s hover:bg-slate-200 text-xl">
                    <img
                      src={handwritten}
                      alt="Handwritten"
                      className="h-8 w-8 ml-4 my-1"
                    />
                  </Link>

                  <Link to="/history" className="rounded-lg font-bold w-[90px] text-center text-blue-500 hover:text-blue-700 transition-all 1s hover:bg-slate-200 text-xl">
                    <img
                      src={historys}
                      alt="History"
                      className="h-8 w-8 ml-4 my-1"
                    />
                  </Link>
                  <Link to='/search' className="rounded-lg font-bold w-[90px] text-center text-blue-500 hover:text-blue-700 ml-1 transition-all 1s hover:bg-slate-200 text-xl">
                    <img
                      src={searchimage}
                      alt="Search"
                      className="h-8 w-8 ml-4 my-1"
                    />
                  </Link>
                  <Link to='/reception' className="rounded-lg font-bold w-[90px] text-center text-blue-500 hover:text-blue-700 ml-1 transition-all 1s hover:bg-slate-200 text-xl">
                    <MdOutlineReceipt className="h-8 w-8 ml-4 my-1 text-black" />

                  </Link>
                  <Link onClick={install} className="rounded-lg font-bold w-[70px] text-center text-blue-500 hover:text-blue-700 ml-1 transition-all 1s hover:bg-slate-200 text-xl">
                    <p className="whitespace-nowrap"> {t('install')}</p>
                  </Link>
                </>
              )}
              {isNonMedical && (
                <>
                  <Link to="/" className="rounded-lg font-bold w-[100px] text-center text-blue-500 hover:text-blue-700 ml-1 transition-all 1s hover:bg-slate-200 text-xl">
                    <img
                      src={qrimage}
                      alt="QR Code"
                      className="h-8 w-8 ml-4 my-1"
                    />
                  </Link>
                  {/* <Link to="/scanner" className="rounded-lg font-bold w-[100px] text-center text-blue-500 hover:text-blue-700 ml-1 transition-all 1s hover:bg-slate-200 text-xl">
                    <MdOutlineQrCodeScanner className="h-8 w-8 ml-4 my-1 text-black" />
                  </Link> */}

                  <Link to='/search' className="rounded-lg font-bold w-[100px] text-center text-blue-500 hover:text-blue-700 ml-1 transition-all 1s hover:bg-slate-200 text-xl">
                    <img
                      src={searchimage}
                      alt="Search"
                      className="h-8 w-8 ml-4 my-1"
                    />
                  </Link>
                  {/* <Link to='/upload' className="rounded-lg font-bold w-[100px] text-center text-blue-500 hover:text-blue-700 transition-all 1s hover:bg-slate-200 whitespace-nowrap text-xl">
                    <FaUpload className="h-8 w-8 ml-4 my-1 text-black" />
                  </Link> */}

                  <Link to='/report-file' className="rounded-lg font-bold w-[100px] text-center text-blue-500 hover:text-blue-700 transition-all 1s hover:bg-slate-200 whitespace-nowrap text-xl">
                    <MdOutlineReceipt className="h-8 w-8 ml-4 my-1 text-black" />
                  </Link>

                  <Link onClick={install} className="rounded-lg font-bold w-[70px] text-center text-blue-500 hover:text-blue-700 ml-1 transition-all 1s hover:bg-slate-200 text-xl">
                    <p className="whitespace-nowrap"> {t('install')}</p>
                  </Link>
                </>
              )}

              {
                isHospital && (
                  <>
                    <Link to="/" className="rounded-lg font-bold w-[100px] text-center text-blue-500 hover:text-blue-700 ml-1 transition-all 1s hover:bg-slate-200 text-xl">
                      <img
                        src={qrimage}
                        alt="QR Code"
                        className="h-8 w-8 ml-4 my-1"
                      />
                    </Link>
                    <Link to="/billing" className="py-2 text-center text-blue-500 hover:text-blue-700 font-bold w-[100px] text-xl">
                      <FaRegMoneyBillAlt className="h-8 w-8 ml-4 my-1 text-black" />
                    </Link>
                    <Link to="/hospitalreception" className="py-2 text-center text-blue-500 hover:text-blue-700 font-bold w-[100px] text-xl">
                      <MdOutlineReceipt className="h-8 w-8 ml-4 my-1 text-black" />
                    </Link>
                    <Link onClick={install} className="rounded-lg font-bold w-[70px] text-center text-blue-500 hover:text-blue-700 ml-1 transition-all 1s hover:bg-slate-200 text-xl">
                      <p className="whitespace-nowrap"> {t('install')}</p>
                    </Link>
                  </>

                )
              }

            </>
          ) : (
            <Link to="/" className="rounded-lg font-bold w-[100px] text-center text-blue-500 hover:text-blue-700 ml-1 transition-all 1s hover:bg-slate-200 text-xl">
              {t('register.home')}
            </Link>
          )}
        </div>
        <div className="w-20 mt-1 ml-1">
          <select
            onChange={(e) => changeLanguage(e.target.value)}
            className="block w-full mt-1 border-gray-600 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            defaultValue={i18n?.language}
          >
            <option value="en">English</option>
            <option value="hi">हिंदी</option>
          </select>
        </div>

        <div className="h-8 mt-1 mr-4 flex items-center py-3">
          {localStorage.getItem("user_name") ? (
            <span className="block truncate text-md font-medium">
              {localStorage.getItem("user_name")}
              <button
                className="inline-flex w-auto cursor-pointer select-none appearance-none items-center justify-center space-x-1 rounded border border-gray-200 bg-red-700 px-2 py-1 text-sm font-medium text-white transition hover:border-red-300 hover:bg-red-600 ml-4 active:bg-red-700 focus:red-gray-300 focus:outline-none focus:ring-2 focus:ring-red-300"
                onClick={setTime}>
                {t('register.logout')}
              </button>
            </span>
          ) : (
            <Link to='/login' className="rounded-lg font-bold w-[100px] text-center text-blue-500 hover:text-blue-700 ml-1 transition-all 1s hover:bg-slate-200 text-xl">
              {t('register.login')}
            </Link>
          )}
        </div>
      </div>

      {/* Toggle bar for phones */}
      <div className="md:hidden flex justify-between border ">
        <button onClick={toggleMenu} className="p-2">
          <svg className="w-10 h-10" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>

        <div className="w-20 mt-2 ml-1">
          <select
            onChange={(e) => changeLanguage(e.target.value)}
            className="block w-full mt-1 border-gray-600 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            defaultValue={i18n?.language}
          >
            <option value="en">English</option>
            <option value="hi">हिंदी</option>
          </select>
        </div>
        <div className="h-8 mt-2 flex items-center py-3">
          {localStorage.getItem("user_name") ? (
            <span className="block truncate text-sm font-medium">
              {localStorage.getItem("user_name")}
              <button
                className="inline-flex w-auto cursor-pointer select-none appearance-none items-center justify-center space-x-1 rounded border border-gray-200 bg-red-700 px-2 py-1 text-sm font-medium text-white transition hover:border-red-300 hover:bg-red-600 ml-4 active:bg-red-700 focus:red-gray-300 focus:outline-none focus:ring-2 focus:ring-red-300"
                onClick={setTime}>
                {t('register.logout')}
              </button>
            </span>
          ) : (
            <Link to='/login' className="rounded-lg font-bold w-[100px] text-center text-blue-500 hover:text-blue-700 ml-1 transition-all 1s hover:bg-slate-200 text-xl mt-6">
              {t('register.login')}
            </Link>
          )}
        </div>
      </div>

      {/* Responsive menu for phones */}
      {showMenu && (
        <div className="md:hidden flex flex-col items-center border">
          {isLoggedIn ? (
            <>
              {isDoctor && (
                <>
                  <Link to="/" className="py-2 text-center text-blue-500 hover:text-blue-700 font-bold w-[100px] text-xl">

                    <img
                      src={qrimage}
                      alt="QR Code"
                      className="h-8 w-8 ml-4 my-1"
                    />
                  </Link>
                  <Link to="/handwritten" className="py-2 text-center text-blue-500 hover:text-blue-700 font-bold w-[100px] text-xl">
                    <img
                      src={handwritten}
                      alt="Handwritten"
                      className="h-8 w-8 ml-4 my-1"
                    />
                  </Link>
                  <Link to="/history" className="py-2 text-center text-blue-500 hover:text-blue-700 font-bold w-[100px] text-xl">
                    <img
                      src={historys}
                      alt="History"
                      className="h-8 w-8 ml-4 my-2"
                    />
                  </Link>

                  <Link to='/search' className="py-2 text-center text-blue-500 hover:text-blue-700 font-bold w-[100px] text-xl">
                    <img
                      src={searchimage}
                      alt="Search"
                      className="h-8 w-8 ml-4 my-1"
                    />
                  </Link>
                  <Link to='/reception' className="py-2 text-center text-blue-500 hover:text-blue-700 font-bold w-[100px] text-xl">
                    <MdOutlineReceipt className="h-8 w-8 ml-4 my-1 text-black" />
                  </Link>
                  <Link onClick={install} className="py-2 text-center text-blue-500 hover:text-blue-700 font-bold w-[100px] text-xl">
                    <p className="whitespace-nowrap"> {t('install')}</p>
                  </Link>
                </>
              )}
              {isNonMedical && (
                <>

                  <Link to="/" className="py-2 text-center text-blue-500 hover:text-blue-700 font-bold w-[100px] text-xl">
                    <img
                      src={qrimage}
                      alt="QR Code"
                      className="h-8 w-8 ml-4 my-1"
                    />
                  </Link>

                  <Link to="/scanner" className="py-2 text-center text-blue-500 hover:text-blue-700 font-bold w-[100px] text-xl">

                    <MdOutlineQrCodeScanner className="h-8 w-8 ml-4 my-1 text-black" />

                  </Link>


                  {/* <Link to="/upload" className="py-2 text-center text-blue-500 hover:text-blue-700 font-bold w-[100px] text-xl" >
                    <FaUpload className="h-8 w-8 ml-4 my-1 text-black" />
                  </Link> */}

                  <Link to='/report-file' className="rounded-lg font-bold w-[100px] text-center text-blue-500 hover:text-blue-700 transition-all 1s hover:bg-slate-200 whitespace-nowrap text-xl">
                    <MdOutlineReceipt className="h-8 w-8 ml-4 my-1 text-black" />
                  </Link>

                  <Link onClick={install} className="py-2 text-center text-blue-500 hover:text-blue-700 font-bold w-[100px] text-xl">
                    <p className="whitespace-nowrap"> {t('install')}</p>
                  </Link>
                </>
              )}

              {
                isHospital && (
                  <>
                    <Link to="/" className="py-2 text-center text-blue-500 hover:text-blue-700 font-bold w-[100px] text-xl">
                      <img
                        src={qrimage}
                        alt="QR Code"
                        className="h-8 w-8 ml-4 my-1"
                      />
                    </Link>
                    <Link to="/billing" className="py-2 text-center text-blue-500 hover:text-blue-700 font-bold w-[100px] text-xl">
                      <FaRegMoneyBillAlt className="h-8 w-8 ml-4 my-1 text-black" />
                    </Link>
                    <Link to="/hospitalreception" className="py-2 text-center text-blue-500 hover:text-blue-700 font-bold w-[100px] text-xl">
                      <MdOutlineReceipt className="h-8 w-8 ml-4 my-1 text-black" />
                    </Link>
                    <Link onClick={install} className="py-2 text-center text-blue-500 hover:text-blue-700 font-bold w-[100px] text-xl">
                      <p className="whitespace-nowrap"> {t('install')}</p>
                    </Link>
                  </>
                )
              }

            </>
          ) : (
            <Link to="/" className="py-2 text-center text-blue-500 hover:text-blue-700 font-bold w-[100px] text-xl">
              {t('register.home')}
            </Link>
          )}
        </div>
      )}
    </>
  );
}
