// import React, { useEffect, useState } from 'react';
// const PDFJS = require("pdfjs-dist/webpack");

// const PdfToImage = ({ pdfUrl }) => {
//     const [imageUrl, setImageUrl] = useState(null);

//     useEffect(() => {
//         const loadPdf = async () => {
//             const loadingTask = PDFJS.getDocument(pdfUrl);
//             const pdf = await loadingTask.promise;
//             const page = await pdf.getPage(1);

//             const scale = 1.5;
//             const viewport = page.getViewport({ scale });

//             const canvas = document.createElement('canvas');
//             const context = canvas.getContext('2d');
//             canvas.height = viewport.height;
//             canvas.width = viewport.width;

//             const renderContext = {
//                 canvasContext: context,
//                 viewport: viewport,
//             };

//             await page.render(renderContext).promise;

//             const imgDataUrl = canvas.toDataURL('image/png');
//             setImageUrl(imgDataUrl);
//         };

//         loadPdf();
//     }, [pdfUrl]);

//     return (
//         <span>
//             {imageUrl ? (

//                 <img src={imageUrl} alt="PDF Page as Image" className="h-[180px] w-[180px]" />

//             ) : (
//                 <img src={imageUrl} alt="Loading..." className="h-[180px] w-[180px]" />
//             )}
//         </span>
//     );
// };

// export default PdfToImage;

import React, { useEffect, useState } from 'react';
import Assign from './Component/Assign';

const PDFJS = require("pdfjs-dist/webpack");

const PdfToImage = ({ pdfUrl, fileId, mainId }) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    console.log("auth", fileId.authorization)
    const loadPdf = async () => {
      const loadingTask = PDFJS.getDocument(pdfUrl);
      const pdf = await loadingTask.promise;
      const page = await pdf.getPage(1);

      const scale = 1.5;
      const viewport = page.getViewport({ scale });

      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      await page.render(renderContext).promise;
      const imgDataUrl = canvas.toDataURL('image/png');
      setImageUrl(imgDataUrl);
    };

    const timeoutId = setTimeout(() => {
      loadPdf();
    }, 500); // Run after 0.5 seconds

    return () => clearTimeout(timeoutId);
  }, [pdfUrl]);

  const [role, setRole] = useState();
  useEffect(() => { setRole(localStorage.getItem('user_role')) }, [])

  // if (fileId.authorization?.includes(role)) {
  return (
    <div className="pdf-to-image-container border  rounded-lg bg-white">
      <span className="flex justify-center">
        {imageUrl ? (<>
          <img src={imageUrl} alt="PDF Page as Image" className="h-[180px] w-[180px] " style={{ objectPosition: 'center' }} />
        </>) : (
          <div className="text-gray-500 h-[180px] w-[180px]">Loading...</div>
        )}
      </span>
      {/* {role === 'Hospital' && <Assign fileId={fileId._id} mainId={mainId} file={fileId} />} */}
    </div>
  );
  // }

  return null;

};

export default PdfToImage;

