import React, { useState, useEffect, useRef } from 'react';
import { Rnd } from 'react-rnd';

const DraggableComponent = ({ id }) => {
    const [position, setPosition] = useState({ x: 50, y: 200 });
    const [size, setSize] = useState({ width: "300px", height: "100px" });
    const [isFocused, setIsFocused] = useState(true); // Track whether the contenteditable div is focused
    const [text, setText] = useState(''); // Track the typed text
    const contentEditableRef = useRef(null); // Reference for contenteditable div

    // Automatically focus on the contenteditable div when the component is mounted
    useEffect(() => {
        if (contentEditableRef.current) {
            contentEditableRef.current.focus();
        }
    }, [contentEditableRef]);

    // Handle input changes from the contenteditable div
    const handleInput = (e) => {
        setText(e.target.innerText); // Use innerText to get the current content
    };

    return (
        <div >
            <Rnd
                size={size}
                position={position}
                onDragStop={(e, d) => setPosition({ x: d.x, y: d.y })}
                onResizeStart={() => setIsFocused(true)}
                onResizeStop={(e, direction, ref, delta, newPosition) => {
                    setSize({ width: ref.style.width, height: ref.style.height });
                    setPosition(newPosition);
                }}
                enableResizing={{
                    top: true,
                    right: true,
                    bottom: true,
                    left: true,
                    topRight: true,
                    bottomRight: true,
                    bottomLeft: true,
                    topLeft: true,
                }} // Enable resizing in all directions
                scale={1.5} // Set scale factor
            >
                <div
                    ref={contentEditableRef}
                    contentEditable
                    onInput={handleInput}
                    style={{
                        padding: '10px',
                        backgroundColor: 'transparent', // Transparent background when not focused
                        border: isFocused ? '1px dotted black' : 'none', // Show black dotted border when focused
                        height: '100%',
                        width: '100%',
                        whiteSpace: 'pre-wrap',  // Preserve line breaks and wrap text
                        wordWrap: 'break-word',
                        overflow: 'auto', // Ensure the content can scroll if it overflows
                        outline: 'none',
                        fontSize: '20px',
                    }}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                >
                    {text}
                </div>
            </Rnd>
        </div>
    );
};

export default DraggableComponent;
