import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosInterceptor';

const Assign = ({ file, fileId, mainId }) => {
  const [roles, setRoles] = useState(['Doctor', 'Patient', 'Nurse']);
  const [selectedRole, setSelectedRole] = useState('');
  const [assignedArray, setAssignedArray] = useState(file.authorization);
  const user_role = localStorage.getItem('user_role');
  const [accessWindow, setAccessWindow] = useState(false);

  useEffect(() => { console.log(mainId) }, [selectedRole]);

  const handleRoleChange = (e) => {
    if (user_role === 'Hospital') {
      setSelectedRole(e.target.value);
    }
  };

  const handleSubmit = async () => {
    try {
      console.log(fileId, selectedRole);
      const response = await axiosInstance.put('api/files/authorize', {
        mainId,
        fileId,
        role: selectedRole,
        action: 'add',
      });
      alert(response.data.message || 'Role updated successfully');
      if (selectedRole && !assignedArray.includes(selectedRole)) {
        setAssignedArray((prev) => [...prev, selectedRole]);
      }
    } catch (error) {
      alert(error.response?.data?.message || 'Error updating role');
    }
  };

  const handleRemoveRole = async (role) => {
    if (role === 'Hospital') {
      alert("Hospital cannot be removed!!");
    }
    else {
      try {
        const response = await axiosInstance.put('api/files/authorize', {
          mainId,
          fileId,
          role: role,
          action: 'remove',
        });
        alert(response.data.message || 'Role removed successfully');
        setAssignedArray((prev) => prev.filter((r) => r !== role));
      } catch (error) {
        alert(error.response?.data?.message || 'Error removing role');
      }
    }
  };

  return (
    <>
      <div className="h-fit">
        <button
          onClick={(e) => {
            e.stopPropagation();
            setAccessWindow(true);
          }}
          className="mt-4 bg-blue-500 text-sm text-white px-2 cursor-pointer rounded-sm hover:bg-blue-600"
        >
          Update Access
        </button>
      </div>
      {accessWindow && (
        <div
          className="cursor-default h-screen w-screen bg-gray-200 fixed top-0 left-0 flex justify-center items-center"
          onClick={(e) => {
            e.stopPropagation();
            setAccessWindow(false);
          }}
        >
          <div className="h-[300px] w-[500px] bg-white" onClick={(e) => e.stopPropagation()}>
            <div
              className="cursor-pointer flex justify-center items-center float-right h-6 w-6 m-2"
              onClick={(e) => {
                e.stopPropagation();
                setAccessWindow(false);
              }}
            >
              X
            </div>
            <div className="p-2">
              <div className="roles-container mt-1">
                <h4 className="text-sm font-semibold">Assign Role</h4>
                {user_role === 'Hospital' ? (
                  <div>
                    <div className="roles-list mt-1 text-sm border border-gray-300 p-1 rounded-sm">
                      <select
                        value={selectedRole}
                        onChange={handleRoleChange}
                        className="w-full p-1 border border-gray-300 rounded-sm"
                      >
                        <option value="" disabled>
                          Select a role
                        </option>
                        {roles.map((role) => (
                          <option key={role} value={role}>
                            {role}
                          </option>
                        ))}
                      </select>
                    </div>
                    <p className="text-sm m-2">Already assigned Roles</p>
                    <div className="h-[100px] overflow-scroll">
                      <div className="grid gap-1">
                        {assignedArray.map((role, index) => (
                          <div key={index} className="text-sm ml-2 flex items-center">
                            <span>{role}</span>
                            <button
                              className="ml-2 text-red-500 hover:text-red-700"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRemoveRole(role);
                              }}
                            >
                              Remove
                            </button>
                          </div>
                        ))}
                      </div>
                    </div>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSubmit();
                      }}
                      className="mt-4 h-8 bg-blue-500 text-sm text-white px-4 rounded-sm hover:bg-blue-600"
                    >
                      Update Role
                    </button>
                  </div>
                ) : (
                  <p className="text-sm text-red-600 whitespace-nowrap">Need permission</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Assign;
