import { useState } from 'react';
import axiosInstance from '../axiosInterceptor';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function SearchBar() {
    const [profession, setProfession] = useState('Doctor');
    const [name, setName] = useState('');
    const [users, setUsers] = useState([]);
    const [noMatch, setNoMatch] = useState(false);
    const { t, i18n } = useTranslation();
    const fetchUsers = async () => {
        const response = await axiosInstance.get(`/api/form/search`, {
            params: {
                profession,
                name,
            },
        });
        setUsers(response.data);
        if (response.data.length === 0) {
            setNoMatch(true);
        } else {
            setNoMatch(false);
        }
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
        fetchUsers();
    };
    const navigate = useNavigate();
    const handleform = (id) => {
        navigate(`/newpatient/${id}`);
    }
    return (
        <div className="max-w-md mx-auto p-4 pt-6 md:p-6 lg:p-12 mt-14">
            <h2 className="text-2xl font-bold mb-4">{t('search_for_doctor')}</h2>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <input
                        type="text"
                        value={name}
                        onChange={handleNameChange}
                        placeholder={t('history.name')}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                    />
                </div>
                <div className="w-full md:w-1/2 px-3">
                    <button
                        onClick={fetchUsers}
                        className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded"
                    >
                        {t('search')}
                    </button>
                </div>
            </div>
            <ul className="list-none mb-0">
                {users.map((user) => (
                    <li key={user._id} className="py-4 border-b border-gray-200 transition-colors duration-300 ease-in-out hover:bg-gray-100 hover:text-blue-600 cursor-pointer" onClick={() => handleform(user._id)}>
                        <span className="text-lg">{user.name}</span>
                    </li>
                ))}
                {noMatch && (
                    <li className="py-4 text-gray-500">
                        No matches found
                    </li>
                )}
            </ul>
        </div>
    );
}

export default SearchBar;