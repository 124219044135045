
import React, { useRef, useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import axiosInstance from './axiosInterceptor';
import Draggable from 'react-draggable';
import { useLongPress } from 'use-long-press';
import { MdDelete } from "react-icons/md";
import DragResizeExample from './Component/Typing';
import textimg from "./assets/text.png"
import stampicon from "./assets/stamp.png"


export default function Template({ template, setqrUrl, containerRef, setpdfurl, setIsMounted, isLoading, setIsLoading, formData, stamp, index, length, isErasing, setIsErasing, drawingMode, setDrawingMode, setstamp, pageNames, seturl_id, url_id, uuid, qrCodeUrl, setstampOn }) {

  const canvasRef = useRef(null);



  const onStart = () => {
    console.log("one")
  };

  const onStop = () => {
    console.log("one")
  };

  const dragHandlers = { onStart, onStop };

  // const containerRef = useRef(null)
  const [isDrawing, setIsDrawing] = useState(false);
  // const [isErasing, setIsErasing] = useState(false);
  let inioffy = 0;
  const [offset, setOffset] = useState({ x: 0, y: inioffy });
  // const [drawingMode, setDrawingMode] = useState(true);

  function generateQRCode(dataUrl) {
    const encodedUrl = encodeURIComponent(dataUrl);
    return `https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${encodedUrl}`;
  }

  const handleSaveToPdf = async () => {

    setIsLoading(true);
    const container = containerRef.current;  // Use ref of the container

    if (container) {
      // Remove any elements you don't want in the PDF
      const divsToRemove = container.querySelectorAll('.eraser-control, .drawing-mode-control');
      divsToRemove.forEach(div => div.remove());

      const bioContent = document.querySelector('.bio-content');
      const height_to_add = bioContent ? bioContent.clientHeight + 20 : 0;
      // console.log('Height of .bio-content:', height_to_add);

      const divToPrint = document.querySelector('.divToPrint');
      const height_page = divToPrint ? divToPrint.clientHeight : 0;
      // console.log('Height of .divToPrint:', height_page);

      // Get container dimensions
      const htmlWidth = container.clientWidth;
      const htmlHeight = container.clientHeight;

      // console.log(htmlHeight);
      const topLeftMargin = 0;

      let pdfWidth = htmlWidth + (topLeftMargin * 2);
      let pdfHeight = height_page + 16; // Default height for the pages
      let firstPageHeight = 0;
      if (url_id !== " ") {
        firstPageHeight = pdfHeight;
      } else {
        firstPageHeight = pdfHeight + height_to_add;
      }

      const totalPDFPages = Math.ceil(htmlHeight / pdfHeight) - 1;

      // Capture the HTML content as a canvas
      const canvas = await html2canvas(container, { scale: 1, useCORS: true });

      const imgData = canvas.toDataURL("image/jpeg", 1.0);

      // Create a new jsPDF instance
      const { jsPDF } = window.jspdf;
      const pdf = new jsPDF('p', 'pt', [pdfWidth, firstPageHeight]); // Use the adjusted height for the first page

      // Add the first page with the adjusted height
      if (url_id !== " ") {
        pdf.addImage(imgData, 'PNG', topLeftMargin, -height_to_add, htmlWidth, htmlHeight);
      }
      else
        pdf.addImage(imgData, 'PNG', topLeftMargin, topLeftMargin, htmlWidth, htmlHeight);

      // Add subsequent pages with the default height
      for (let i = 1; i < totalPDFPages; i++) {
        pdf.addPage([pdfWidth, pdfHeight], 'p');

        pdf.addImage(imgData, 'PNG', topLeftMargin, -(pdfHeight * i + height_to_add + 17 * i) + (topLeftMargin * 4), htmlWidth, htmlHeight + 45);
      }

      // Generate the PDF file name
      const today = new Date();
      const year = today.getFullYear().toString().padStart(2, '0');
      const month = (today.getMonth() + 1).toString().padStart(2, '0');
      const day = today.getDate().toString().padStart(2, '0');
      const hours = today.getHours().toString().padStart(2, '0');
      const minutes = today.getMinutes().toString().padStart(2, '0');

      const filename = `${year}${month}${day}_${hours}${minutes}_${localStorage.getItem("user_name")}_${localStorage.getItem("patient_name")}.pdf`;

      // Output the PDF as a blob
      const pdfBlob = pdf.output('blob', { filename });
      // console.log(pdfBlob);

      const cloudinaryUrl = await uploadFileToBackend(pdfBlob, filename);
      setpdfurl(cloudinaryUrl);
      console.log(cloudinaryUrl);

      // Save the PDF URL to localStorage
      const pdfUrl = { url: cloudinaryUrl, timestamp: Date.now() };
      const storedPdfUrls = JSON.parse(localStorage.getItem('pdf-url')) || [];
      storedPdfUrls.push(pdfUrl);
      localStorage.setItem('pdf-url', JSON.stringify(storedPdfUrls));

      console.log("uuuuid", uuid)
      const data = {
        cloudinaryUrl: cloudinaryUrl,
        doctor_id: localStorage.getItem('user_login'),
        patient_id: formData?.bio?.name || " ",
        patient_token: formData?.patient_ID == "" ? formData?._id : " ", // patient is created by receptionist
        patient_uploadId: formData?.patient_ID != "" ? formData?.patient_ID || " " : " ",   // patient is already created
        doctor_name: localStorage.getItem('user_name'),
        pageNames: pageNames,
        url_id: url_id,
        qruuid: localStorage.getItem('uuid'),
        news: false,
      };

      console.log(data)
      await axiosInstance.post(`/api/form/patientuploadfile`, data);

      // Generate and set the QR code URL
      const qrCodeImageUrl = generateQRCode(cloudinaryUrl);
      // console.log('QR Code Image URL:', qrCodeImageUrl);
      setqrUrl(qrCodeImageUrl);

      setIsLoading(false);
    }
    setIsLoading(false);
    return null;
  };







  useEffect(() => {
    window.handleSaveToPdf = handleSaveToPdf;  // Expose to global scope
    window.scrollTo({
      top: 40, // Adjust this value as needed
      behavior: 'smooth' // Change to 'auto' for instant scrolling
    });

  }, []);

  const uploadPdfToCloudinary = async (pdfBlob, fileName) => {
    const formData = new FormData();
    formData.append('file', pdfBlob);
    formData.append('upload_preset', 'bxva8g23'); // Replace with your Cloudinary upload preset
    formData.append('cloud_name', 'drz2uk3vm');
    formData.append('public_id', fileName);

    try {
      const response = await fetch(`https://api.cloudinary.com/v1_1/drz2uk3vm/upload`, {
        method: 'POST',
        body: formData
      });
      const data = await response.json();
      console.log('Cloudinary URL:', data.url);
      return data.url
    } catch (error) {
      console.error('Error uploading PDF:', error);
    }
  };

  const uploadFileToBackend = async (file, filename) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('filename', filename);

    try {
      const response = await axiosInstance.post('/api/form/uploadfiles', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      return response.data.url;
    } catch (error) {
      console.error('Failed to upload file', error);
      throw new Error('Failed to upload file');
    }
  };

  const sendWhatsAppMessage = async (qrCodeImageUrl, recipientNumber) => {
    const token = localStorage.getItem('token');
    try {
      const response = await axiosInstance.post(`/api/sendWhatsApp`, {
        qrCodeImageUrl,
        recipientNumber
      }, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      console.log('WhatsApp message sent:', response.data);
    } catch (error) {
      console.error('Failed to send WhatsApp message:', error);
    }
  }


  useEffect(() => {
    // alert("refreshed")


    setIsMounted(true); // Set isMounted to true to prevent re-running the effect

    const canvas = canvasRef.current;
    inioffy = window.pageYOffset + canvas.getBoundingClientRect().top;
    canvas.style.touchAction = "auto";

    function handleScroll() {
      setOffset({ x: window.scrollX, y: window.scrollY - inioffy });
    }

    function handleResize() {
      canvas.width = canvas.parentElement.clientWidth;
      canvas.height = canvas.parentElement.clientHeight;
    }

    window.addEventListener('scroll', handleScroll);
    // window.addEventListener('resize', handleResize);

    // Initial setup
    handleResize();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };

  }, []);



  const eraserRef = useRef(null);

  // useEffect(() => {
  //   const canvas = canvasRef.current;
  //   const eraser = eraserRef.current;
  //   if (!canvas) return;
  //   const context = canvas.getContext('2d');
  //   let drawing = false;

  //   function shouldHandleTouch(e) {
  //     if (e.touches.length === 2) {
  //       return false;
  //     }
  //     return true;
  //   }

  //   const eraserSize = 30;

  //   const showEraserIcon = (x, y) => {
  //     eraser.style.display = 'block';
  //     eraser.style.left = `${x - eraserSize / 2}px`;
  //     eraser.style.top = `${y - eraserSize / 2}px`;
  //   };
  //   const hideEraserIcon = () => {
  //     eraser.style.display = 'none';
  //   };

  //   const eraseContent = (x, y) => {
  //     context.save();
  //     context.beginPath();
  //     context.arc(x, y, eraserSize / 2, 0, Math.PI * 2, false);
  //     context.clip(); // Clip to the circular path
  //     context.clearRect(x - eraserSize / 2, y - eraserSize / 2, eraserSize, eraserSize);
  //     context.restore();
  //   };

  //   function handlePointerDownTouch(e) {
  //     console.log(e.targetTouches[0].radiusX)
  //     if (e.targetTouches[0].radiusX < 2) {
  //       e.preventDefault();
  //       drawing = true;
  //       canvas.style.touchAction = 'none';
  //       context.beginPath();
  //       context.lineWidth = 1;
  //       context.lineCap = 'round';
  //       context.lineJoin = 'round';
  //       context.shadowColor = "black";
  //       context.shadowBlur = 1;
  //       context.moveTo(e.targetTouches[0].clientX + offset.x, e.targetTouches[0].clientY + offset.y);
  //     }

  //   }

  //   function handlePointerMoveTouch(e) {

  //     if (!drawing) return;
  //     canvas.style.touchAction = 'none';
  //     const x = e.targetTouches[0].clientX + offset.x;
  //     const y = e.targetTouches[0].clientY + offset.y;
  //     if (isErasing) {
  //       // context.clearRect(e.targetTouches[0].clientX + offset.x - 5, e.targetTouches[0].clientY + offset.y - 5, 20, 20);
  //       showEraserIcon(x, y);
  //       eraseContent(x, y);

  //     } else {
  //       if (e.targetTouches[0].radiusX < 2) {
  //         context.lineTo(e.targetTouches[0].clientX + offset.x, e.targetTouches[0].clientY + offset.y);
  //         context.stroke();
  //       }
  //     }
  //   }

  //   function handlePointerUpTouch() {
  //     canvas.style.touchAction = 'auto';
  //     drawing = false;
  //     hideEraserIcon();
  //   }

  //   // function handlePointerDownTouch(e) {
  //   //   e.preventDefault(); // Prevent default touch behavior (e.g., scrolling)
  //   //   const touchX = e.targetTouches[0].clientX + offset.x;
  //   //   const touchY = e.targetTouches[0].clientY + offset.y;

  //   //   // Calculate starting position relative to the cursor pointer
  //   //   const cursorX = touchX - (e.targetTouches[0].radiusX || 0);
  //   //   const cursorY = touchY - (e.targetTouches[0].radiusY || 0);

  //   //   drawing = true;
  //   //   console.log(canvas.style.touchAction)
  //   //   canvas.style.touchAction = 'none';

  //   //   context.beginPath();
  //   //   context.lineWidth = 1;
  //   //   context.lineCap = 'round';
  //   //   context.lineJoin = 'round';
  //   //   context.shadowColor = "black";
  //   //   context.shadowBlur = 0.9;
  //   //   context.moveTo(cursorX, cursorY);
  //   // }

  //   // function handlePointerMoveTouch(e) {
  //   //   if (!drawing) return;
  //   //   // e.preventDefault(); // Prevent default touch behavior (e.g., scrolling)
  //   //   console.log("IS DRAWING", isDrawing)

  //   //   const touchX = e.targetTouches[0].clientX + offset.x;
  //   //   const touchY = e.targetTouches[0].clientY + offset.y;

  //   //   // Calculate position relative to the cursor pointer
  //   //   const cursorX = touchX - (e.targetTouches[0].radiusX || 0);
  //   //   const cursorY = touchY - (e.targetTouches[0].radiusY || 0);

  //   //   canvas.style.touchAction = 'none';
  //   //   if (isErasing) {
  //   //     context.clearRect(cursorX - 5, cursorY - 5, 10, 10);
  //   //   } else {
  //   //     context.lineTo(cursorX, cursorY);
  //   //     context.stroke();
  //   //   }
  //   // }

  //   // function handlePointerUpTouch(e) {
  //   //   // Prevent default touch behavior (e.g., scrolling)
  //   //   canvas.style.touchAction = 'auto';
  //   //   drawing = false;
  //   // }

  //   if (drawingMode || isErasing) {
  //     canvas.addEventListener('touchstart', handlePointerDownTouch);
  //     canvas.addEventListener('touchmove', handlePointerMoveTouch);
  //     canvas.addEventListener('touchend', handlePointerUpTouch);
  //   }


  //   function handlePointerDown(e) {
  //     setIsDrawing(true);
  //     context.beginPath();
  //     context.lineWidth = 1;
  //     context.lineCap = 'round';
  //     context.lineJoin = 'round';
  //     context.shadowColor = "black";
  //     context.shadowBlur = 0.7;
  //     context.moveTo(e.clientX + offset.x, e.clientY + offset.y);

  //   }

  //   function handlePointerMove(e) {
  //     if (!isDrawing) return;
  //     const x = e.clientX + offset.x;
  //     const y = e.clientY + offset.y;
  //     if (isErasing) {
  //       showEraserIcon(e.clientX + offset.x, e.clientY + + offset.y); // Move the eraser icon with the pointer
  //       eraseContent(x, y); // Erase the content under the eraser
  //     } else {
  //       context.lineTo(e.clientX + offset.x, e.clientY + offset.y);
  //       context.stroke();
  //       // context.filter = 'blur(0.5px)';
  //     }
  //   }

  //   function handlePointerUp() {
  //     setIsDrawing(false);
  //     hideEraserIcon();
  //   }
  //   // if (drawingMode || isErasing) {
  //   canvas.addEventListener('mousedown', handlePointerDown);
  //   canvas.addEventListener('mousemove', handlePointerMove);
  //   canvas.addEventListener('mouseup', handlePointerUp);
  //   // }

  //   return () => {
  //     // if (drawingMode || isErasing) {
  //     canvas.removeEventListener('mousedown', handlePointerDown);
  //     canvas.removeEventListener('mousemove', handlePointerMove);
  //     canvas.removeEventListener('mouseup', handlePointerUp);
  //     canvas.removeEventListener('touchstart', handlePointerDownTouch);
  //     canvas.removeEventListener('touchmove', handlePointerMoveTouch);
  //     canvas.removeEventListener('touchend', handlePointerUpTouch);
  //     // }
  //   };
  // }, [isDrawing, isErasing, offset, drawingMode]);


  useEffect(() => {
    const canvas = canvasRef.current;
    const eraser = eraserRef.current;
    if (!canvas) return;
    const context = canvas.getContext('2d');
    let drawing = false;

    // Get the canvas's bounding box to calculate its offset when centered
    const getCanvasOffset = () => {
      const rect = canvas.getBoundingClientRect();
      return { x: rect.left, y: rect.top };
    };

    const eraserSize = 30;

    const showEraserIcon = (x, y) => {
      eraser.style.display = 'block';
      eraser.style.left = `${x - eraserSize / 2}px`;
      eraser.style.top = `${y - eraserSize / 2}px`;
    };
    const hideEraserIcon = () => {
      eraser.style.display = 'none';
    };

    const eraseContent = (x, y) => {
      context.save();
      context.beginPath();
      context.arc(x, y, eraserSize / 2, 0, Math.PI * 2, false);
      context.clip();
      context.clearRect(x - eraserSize / 2, y - eraserSize / 2, eraserSize, eraserSize);
      context.restore();
    };

    const handlePointerDownTouch = (e) => {
      const canvasOffset = getCanvasOffset();
      if (e.targetTouches[0].radiusX < 2) {
        e.preventDefault();
        drawing = true;
        canvas.style.touchAction = 'none';
        context.beginPath();
        context.lineWidth = 1;
        context.lineCap = 'round';
        context.lineJoin = 'round';
        context.shadowColor = "black";
        context.shadowBlur = 1;
        context.moveTo(
          e.targetTouches[0].clientX - canvasOffset.x,
          e.targetTouches[0].clientY - canvasOffset.y
        );
      }
    };

    const handlePointerMoveTouch = (e) => {
      if (!drawing) return;
      const canvasOffset = getCanvasOffset();
      const x = e.targetTouches[0].clientX - canvasOffset.x;
      const y = e.targetTouches[0].clientY - canvasOffset.y;
      if (isErasing) {
        showEraserIcon(x, y);
        eraseContent(x, y);
      } else {
        if (e.targetTouches[0].radiusX < 2) {
          context.lineTo(x, y);
          context.stroke();
        }
      }
    };

    const handlePointerUpTouch = () => {
      canvas.style.touchAction = 'auto';
      drawing = false;
      hideEraserIcon();
    };

    const handlePointerDown = (e) => {
      const canvasOffset = getCanvasOffset();
      setIsDrawing(true);
      context.beginPath();
      context.lineWidth = 1;
      context.lineCap = 'round';
      context.lineJoin = 'round';
      context.shadowColor = "black";
      context.shadowBlur = 0.7;
      context.moveTo(e.clientX - canvasOffset.x, e.clientY - canvasOffset.y);
    };

    const handlePointerMove = (e) => {
      if (!isDrawing) return;
      const canvasOffset = getCanvasOffset();
      const x = e.clientX - canvasOffset.x;
      const y = e.clientY - canvasOffset.y;
      if (isErasing) {
        showEraserIcon(x, y);
        eraseContent(x, y);
      } else {
        context.lineTo(x, y);
        context.stroke();
      }
    };

    const handlePointerUp = () => {
      setIsDrawing(false);
      hideEraserIcon();
    };

    if (drawingMode || isErasing) {
      canvas.addEventListener('touchstart', handlePointerDownTouch);
      canvas.addEventListener('touchmove', handlePointerMoveTouch);
      canvas.addEventListener('touchend', handlePointerUpTouch);
    }

    canvas.addEventListener('mousedown', handlePointerDown);
    canvas.addEventListener('mousemove', handlePointerMove);
    canvas.addEventListener('mouseup', handlePointerUp);

    return () => {
      canvas.removeEventListener('mousedown', handlePointerDown);
      canvas.removeEventListener('mousemove', handlePointerMove);
      canvas.removeEventListener('mouseup', handlePointerUp);
      canvas.removeEventListener('touchstart', handlePointerDownTouch);
      canvas.removeEventListener('touchmove', handlePointerMoveTouch);
      canvas.removeEventListener('touchend', handlePointerUpTouch);
    };
  }, [isDrawing, isErasing, offset, drawingMode]);

  const [selectedTemplateIndex, setSelectedTemplateIndex] = useState(0);
  const [isTemplateSelected, setIsTemplateSelected] = useState(false);

  const bind = useLongPress((e) => {
    const value = e.target.id
    console.log(value)
    // setstamp(prevTemplates => prevTemplates.filter(template => template?.stamp?.id !== value))
  });

  const [components, setComponents] = useState([]);

  const addComponent = () => {
    // Add a new DraggableComponent to the list
    setComponents([...components, { id: components.length }]);
  };

  return (
    <>

      <div style={{ position: 'relative', height: '100vh' }} className='flex justify-center my-4 divToPrint box' id="divToPrint" >

        <div ref={eraserRef} style={{ position: 'absolute', display: 'none', width: '30px', height: '30px', borderRadius: '50%', border: '2px solid black', pointerEvents: 'none' }} />
        <canvas
          ref={canvasRef}
          style={{ position: 'absolute', top: 0, left: 0, margin: '0px' }}
          className={`touch-auto border border-black`}

        />
        <div
          className={`z-10 fixed top-96 left-2 border rounded-md ${isErasing ? "bg-gray-300" : "bg-white"} eraser-control`}
          onClick={() => { setIsErasing(!isErasing); setDrawingMode(false) }}
        >
          <img className='h-10 w-10' src="eraser.png" alt="" />
        </div>
        <div
          className={`z-10 fixed top-80 left-2 border rounded-md ${drawingMode ? "bg-gray-300" : "bg-white"} drawing-mode-control`}
          onClick={() => { setDrawingMode(!drawingMode); setIsErasing(false); }}
        >
          <img
            className='h-10 w-10'
            src="pencil.png"
            alt=""
          />
        </div>


        {/* {stamp?.length > 0 && length == index + 1 && ( */}
        <div > {/* Adjust grid class for desired layout */}
          {stamp.map((file, val) => (
            <Draggable>
              <div key={file?.stamp?.url} className={`z-10 absolute top-24 left-48`} style={{ zIndex: 5 }}
                {...bind()} >
                {file.pageNumber == index ?
                  <img className="h-[180px] w-[180px] xl:h-[120px] xl:w-[120px] " src={file?.stamp?.url} alt="" id={file?.stamp?.id} /> : ""}
              </div>
            </Draggable>
          ))}
        </div>

        <div
          className={`z-10 fixed top-64 left-2 border rounded-md bg-white eraser-control`}
          onClick={addComponent}
        >
          <img
            className='h-10 w-10'
            src={textimg}
            alt=""
          />
        </div>
        <div >
          {components.map((component) => (
            <DragResizeExample key={component.id} id={component.id} />
          ))}
        </div>
        <div
          className={`z-10 fixed top-48 left-2 border rounded-md  drawing-mode-control`}
          onClick={() => setstampOn(true)}
        >
          <img
            className='h-10 w-10'
            src={stampicon}
            alt=""
          />
        </div>
        {url_id == " " ?
          < div className={`z-1 absolute bottom-1 left-1`}>
            <img src={qrCodeUrl} alt="QR Code" className="h-[180px] w-[180px] xl:h-[120px] xl:w-[120px] bg-transparent" />
          </div >
          : ""
        }


        <img id='Template' className='Template  w-full  ' src={template?.url} alt="LOGO" />
      </div >
    </>
  );
} 