import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from '../axiosInterceptor';
import { useTranslation } from 'react-i18next';
const Register = () => {


    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [blur, setBlur] = useState("noblur");
    const [loading, setLoading] = useState("noloader");
    const [message, setMessage] = useState("");
    const [user, setUser] = useState({
        name: "",
        email: "",
        phone: "",
        dob: "",
        profession: "",
        subProfession: "",
        department: "",
        hospital: "",
        occupation: "",
        income: "",
        password: "",
        reEnterPassword: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser({
            ...user,
            [name]: value,
        });
        console.log(user);
    };

    const register = () => {
        setLoading("loader");
        setBlur("blur");
        const { name, phone, dob, profession, password, reEnterPassword, email } = user;
        if (name && phone && dob && profession && email && password && password === reEnterPassword) {
            if (isNaN(phone)) {
                setMessage("Invalid Number");
                setLoading("noloader");
                setBlur("noblur");
            } else {
                axiosInstance.post(`/api/form/register`, user).then((res) => {
                    setMessage(res.data.message);
                    setLoading("noloader");
                    setBlur("noblur");
                }).catch((error) => {
                    console.error(error);
                    setLoading("noloader");
                    setBlur("noblur");
                });
            }
        } else {
            setMessage("All fields are required");
            setLoading("noloader");
            setBlur("noblur");
        }
    };

    return (
        <>
            <div className={blur}>
                <div className={loading}></div>
            </div>
            <div className="flex justify-center items-center">
                <div className="register w-full">
                    <h2>{t('register.register')}</h2>
                    <div className="flex flex-col gap-4">
                        <input
                            className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-purple-500"
                            type="text"
                            name="name"
                            value={user.name}
                            placeholder={t('register.name')}
                            onChange={handleChange}
                        />
                        <input
                            className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-purple-500"
                            type="text"
                            name="email"
                            value={user.email}
                            placeholder={t('register.email')}
                            onChange={handleChange}
                        />
                        <input
                            className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-purple-500"
                            type="tel"
                            pattern="[0-9]{10}"
                            name="phone"
                            value={user.phone}
                            placeholder={t('register.phone')}
                            maxLength="10"
                            onChange={handleChange}
                        />
                        <input
                            className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-purple-500"
                            type="date"
                            name="dob"
                            value={user.dob}
                            placeholder="DOB"
                            onChange={handleChange}
                        />
                        <select name="profession" className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-purple-500" value={user.profession} onChange={handleChange}>
                            <option value="">-- {t('register.profession')} --</option>
                            <option value="Medical">{t('register.medical')}</option>
                            <option value="Non-Medical">{t('register.nonmedical')}</option>
                        </select>
                        {user.profession === "Medical" && (
                            <select name="subProfession" className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-purple-500" value={user.subProfession} onChange={handleChange}>
                                <option value="">-- {t('register.sub_profession')} --</option>
                                <option value="Doctor">{t('register.doctor')}</option>
                                <option value="Hospital">{t('register.hospital')}</option>
                                <option value="Corporate">{t('register.corporate')}</option>
                            </select>
                        )}
                        {user.profession === "Non-Medical" && (
                            <>
                                <input
                                    className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-purple-500"
                                    type="text"
                                    name="occupation"
                                    value={user.occupation}
                                    placeholder={t('register.occupation')}
                                    onChange={handleChange}
                                />
                                <input
                                    className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-purple-500"
                                    type="text"
                                    name="income"
                                    value={user.income}
                                    placeholder={t('register.income')}
                                    onChange={handleChange}
                                />
                            </>
                        )}
                        {user.profession === "Medical" && user.subProfession === "Doctor" && (
                            <>
                                <input
                                    className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-purple-500"
                                    type="text"
                                    name="department"
                                    value={user.department}
                                    placeholder={t('register.department')}
                                    onChange={handleChange}
                                />
                                <input
                                    className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-purple-500"
                                    type="text"
                                    name="hospital"
                                    value={user.hospital}
                                    placeholder={t('register.hospital')}
                                    onChange={handleChange}
                                />
                            </>
                        )}
                        <input
                            type="password"
                            name="password"
                            className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-purple-500"
                            value={user.password}
                            placeholder={t('register.password')}
                            onChange={handleChange}
                        />
                        <input
                            type="password"
                            name="reEnterPassword"
                            className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-purple-500"
                            value={user.reEnterPassword}
                            placeholder={t('register.re_password')}
                            onChange={handleChange}
                        />
                    </div>
                    <p className="messaged">{message}</p>
                    <div className="button" onClick={register}>
                        {t('register.register')}
                    </div>
                    <div style={{ color: "black" }}>{t('register.or')}</div>
                    <div style={{ color: "black" }}>
                        {t('register.back')} <span className="xyz" onClick={() => navigate("/login")}>{t('register.home')}</span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Register;

