import React, { useState, useEffect } from "react";
import axiosInstance from "./axiosInterceptor";
import PdfToImage from "./PdfToImage";
const PDFJS = require("pdfjs-dist/webpack");

const ReimbursementForm = () => {
    const [pdfUrls, setPdfUrls] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [previewUrls, setPreviewUrls] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [billFiles, setBillFiles] = useState([]);
    const [billFileUrls, setBillFileUrls] = useState([]);
    const [amount, setAmount] = useState("");
    const [uploadedBillUrls, setUploadedBillUrls] = useState([]); // Store uploaded bill URLs

    useEffect(() => {
        const fetchPdfUrls = async () => {
            try {
                const response = await axiosInstance.get(
                    `/api/form/pdf-urls/patient/${localStorage.getItem("user_login")}`
                );
                setPdfUrls(response.data);
            } catch (error) {
                console.error("Error fetching PDF URLs", error);
            }
        };

        fetchPdfUrls();
    }, []);

    const handleAttachFile = () => {
        setShowPopup(true);
    };
    const readFileData = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (e) => {
                resolve(e.target.result);
            };
            reader.onerror = (err) => {
                reject(err);
            };
            reader.readAsDataURL(file);
        });
    };

    const handleFileUpload = async (e) => {
        const files = Array.from(e.target.files);
        setBillFiles((prev) => [...prev, ...files]);
        const fileUrls = files.map((file) => URL.createObjectURL(file));
        setBillFileUrls((prev) => [...prev, ...fileUrls]);

        // Upload each file immediately after selection
        for (const file of files) {
            let cloudinaryUrls = [];

            if (file.type === "application/pdf") {
                const images = await convertPdfToImages(file);
                for (let i = 0; i < images.length; i++) {
                    const cloudinaryUrl = await uploadFileToBackend(images[i], `${file.name}-page-${i + 1}`);
                    cloudinaryUrls.push(cloudinaryUrl);
                }
            } else if (file.type === "image/jpeg" || file.type === "image/png") {
                const cloudinaryUrl = await uploadFileToBackend(file, file.name);
                cloudinaryUrls.push(cloudinaryUrl);
            }

            setUploadedBillUrls((prev) => [...prev, ...cloudinaryUrls]);
            // Store uploaded bill URLs
        }
    };

    const convertPdfToImages = async (file) => {
        const images = [];
        try {
            const data = await readFileData(file);
            const pdf = await PDFJS.getDocument(data).promise;

            for (let i = 0; i < pdf.numPages; i++) {
                const page = await pdf.getPage(i + 1);
                const viewport = page.getViewport({ scale: 1 });
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");

                canvas.height = viewport.height;
                canvas.width = viewport.width;

                await page.render({ canvasContext: context, viewport: viewport }).promise;

                images.push(canvas.toDataURL("image/jpeg"));
                canvas.remove();
            }
        } catch (error) {
            console.error('Error converting PDF to images:', error);
        }
        return images;
    };

    const uploadFileToBackend = async (file, filename) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('filename', filename);

        try {
            const response = await axiosInstance.post('/api/form/uploadfiles', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            return response.data.url;
        } catch (error) {
            console.error('Failed to upload file', error);
            throw new Error('Failed to upload file');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const reimbursementData = {
            amount: amount, // state for amount
            patient_id: localStorage.getItem("user_login"), // or use actual patient ID
            patient_name: localStorage.getItem("user_name"), // dynamically set based on your form data
            prescriptions: selectedFiles, // an array of prescription objects
            bills: uploadedBillUrls,
        };

        try {
            const response = await axiosInstance.post('/api/formcreation/reimbursement', reimbursementData);
            console.log('Reimbursement submitted successfully:', response.data);
        } catch (error) {
            console.error('Error submitting reimbursement:', error);
        }

        console.log(amount);
        console.log(uploadedBillUrls)
        console.log(selectedFiles)

    };

    const handleFileClick = (file) => {
        if (selectedFiles.some((selectedFile) => selectedFile._id === file._id)) {
            setSelectedFiles((prev) => prev.filter((selectedFile) => selectedFile._id !== file._id));
            setPreviewUrls((prev) => prev.filter((url) => url !== file?.urls));
        } else {
            setSelectedFiles((prev) => [...prev, file]);
            setPreviewUrls((prev) => [...prev, file?.urls]);
        }
    };

    return (
        <div className="p-8">
            <h2 className="text-2xl font-bold mb-4">Reimbursement Form</h2>
            <form onSubmit={handleSubmit} className="space-y-6">
                {/* Upload Bill */}
                <div>
                    <label className="block font-semibold mb-2">Upload Bill</label>
                    <input
                        type="file"
                        accept=".pdf,.jpg,.png"
                        multiple
                        onChange={handleFileUpload}
                    />
                    {uploadedBillUrls.length > 0 && (
                        <div className="mt-4 p-2 border border-gray-300 overflow-x-auto">
                            <div style={{ width: "150px" }} className="flex space-x-4 ">
                                {uploadedBillUrls.map((url, idx) => (
                                    <img key={idx} src={url} alt="Bill preview" className="h-[180px] w-[180px]" />
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                {/* Attach File */}
                <div>
                    <label className="block font-semibold mb-2">Attach File</label>
                    <button
                        type="button"
                        className="px-4 py-2 bg-blue-500 text-white rounded-md"
                        onClick={handleAttachFile}
                    >
                        Attach File
                    </button>
                    {previewUrls.length > 0 && (
                        <div className="mt-4 p-2 border border-gray-300 overflow-x-auto">
                            <div style={{ width: "150px" }} className="flex space-x-4 ">
                                {previewUrls.map((url, idx) => (
                                    <div key={idx} className="flex flex-col items-center">
                                        <PdfToImage pdfUrl={url[0]?.url} fileId={url}/>
                                        <p className="whitespace-nowrap">
                                            {new Date(url[0]?.timestamp).toLocaleDateString()}{" "}
                                            {new Date(url[0]?.timestamp).toLocaleTimeString()}
                                        </p>
                                        <p className="whitespace-nowrap">{url[0]?.doctor_name}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>

                {/* Amount */}
                <div>
                    <label className="block font-semibold mb-2">Enter Amount</label>
                    <input
                        type="number"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                        placeholder="Enter amount"
                        className="w-full px-4 py-2 border border-gray-300 rounded-md"
                    />
                </div>

                {/* Submit */}
                <button type="submit" className="px-4 py-2 bg-green-500 text-white rounded-md">
                    Submit
                </button>
            </form>

            {/* Popup for showing attached files */}
            {showPopup && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-6 rounded-lg relative w-3/4 max-w-3xl h-3/4 max-h-full overflow-auto">
                        <button
                            className="absolute top-4 right-4 text-black-700 hover:text-red-700"
                            onClick={() => setShowPopup(false)}
                        >
                            <img className="h-10 w-10" src="/cross2.png" alt="X" />
                        </button>
                        <h3 className="text-xl font-bold mb-4">Attached Files</h3>

                        <div className="overflow-x-auto max-h-full">
                            {pdfUrls.length > 0 ? (
                                <ul className="flex space-x-4">
                                    {pdfUrls[0]?.files?.map((file, fileIndex) => (
                                        <div
                                            key={fileIndex}
                                            className={`flex flex-col items-center cursor-pointer ${selectedFiles.includes(file) ? "border border-black" : ""}`}
                                            onClick={() => handleFileClick(file)}
                                        >
                                            <div style={{ width: "150px" }}>
                                                <PdfToImage pdfUrl={file?.urls[0]?.url} fileId={file} mainId={pdfUrls[0]._id}/>
                                            </div>
                                            <p>{file?.doctor_name}</p>
                                            <p>{file?.timestamp}</p>
                                        </div>
                                    ))}
                                </ul>
                            ) : (
                                <p>No attached files available.</p>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ReimbursementForm;
