import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from '../axiosInterceptor';
import { FaGoogle } from "react-icons/fa";
import { useTranslation } from 'react-i18next';


const Login = () => {
    const { t, i18n } = useTranslation();
    const [blur, setBlur] = useState("noblur");
    const [loading, setLoading] = useState("noloader");
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const [user, setUser] = useState({
        email: "",
        password: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser({
            ...user,
            [name]: value,
        });
    };

    const calculateUserAge = (dobStr) => {
        const dob = new Date(dobStr);
        const today = new Date();

        let age = today.getFullYear() - dob.getFullYear();
        const monthDiff = today.getMonth() - dob.getMonth();
        const dayDiff = today.getDate() - dob.getDate();

        // Adjust age if the birthday hasn't occurred yet this year
        if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
            age--;
        }

        return age;
    };


    const login = async () => {
        setLoading("loader");
        setBlur("blur");
        const { email, password } = user;
        if (email && password) {
            try {
                const res = await axiosInstance.post(`/api/form/login`, user);
                setLoading("noloader");
                setBlur("noblur");
                setMessage(res.data.message);
                if (typeof res.data.userId !== "undefined") {
                    localStorage.setItem("user_login", res.data.userId);
                    localStorage.setItem("user_name", res.data.name);
                    localStorage.setItem('user_role', res.data.role);
                    localStorage.setItem('user_email', res.data.email);
                    const dobStr = res.data.age;
                    const age = calculateUserAge(dobStr);
                    localStorage.setItem('user_age', age);
                    localStorage.setItem('user_phone', res.data.phone);
                    localStorage.setItem("token", res.data.token);
                    navigate("/");
                    window.location.reload(); // Reload the page
                }
            } catch (err) {
                console.log(err);
                setLoading("noloader");
                setBlur("noblur");
                setMessage("Login failed");
            }
        } else {
            setMessage("All fields are required");
            setLoading("noloader");
            setBlur("noblur");
        }
    };

    const handlebutton = (e) => {
        e.preventDefault();
        navigate("/googlelogin");
    }

    return (
        <>
            <div className={blur}>
                <div className={loading}></div>
            </div>
            <div className="flex flex-col lg:flex-row items-center justify-center min-h-screen w-screen">
                <div className="w-full lg:w-1/3 mb-6 lg:mb-0 overflow-y-auto">
                    <div className="login w-full">
                        <h2>{t('register.login')}</h2>
                        <div className="flex flex-col gap-4">
                            <input
                                className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-purple-500"
                                type="text"
                                name="email"
                                value={user.email}
                                placeholder={t('register.email')}
                                onChange={handleChange}
                            />
                            <input
                                type="password"
                                name="password"
                                className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:border-purple-500"
                                value={user.password}
                                placeholder={t('register.password')}
                                onChange={handleChange}
                            />
                        </div>
                        <p className="messaged">{message}</p>

                        <div className="button" onClick={login}>
                            {t('register.login')}
                        </div>
                        <div style={{ color: "black" }}>{t('register.or')}</div>
                        <div style={{ color: "black" }}>
                            <span className="xyz" onClick={() => navigate("/register")}>{t('register.register')}</span>
                        </div>
                        <div className="flex justify-center items-center my-4">
                            <button
                                onClick={handlebutton}
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded flex gap-2"
                            >
                                {t('register.login')}  <FaGoogle />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Login;
