import React, { useState } from "react";
import { Document, Page } from 'react-pdf';

const Example = ({ file_id }) => {
    const [numPages, setNumPages] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');

    function onDocumentLoadSuccess(index, { numPages: loadedPages }) {
        setNumPages(prevPages => {
            const updatedPages = [...prevPages];
            updatedPages[index] = loadedPages;

            // Calculate total pages
            const total = updatedPages.reduce((acc, pages) => acc + (pages || 0), 0);
            setTotalPages(total);

            return updatedPages;
        });
    }

    let cumulativePages = 0;

    // Filter pages based on search term
    const filterPages = (pageNames = [], numPages = 0) => {
        if (!searchTerm) return Array.from({ length: numPages }, (_, index) => index + 1);
        return pageNames
            .map((name, index) => name.toLowerCase().includes(searchTerm.toLowerCase()) ? index + 1 : null)
            .filter(index => index !== null);
    };

    return (
        <div className="  p-4 rounded-lg ">
            <input
                type="text"
                placeholder="Search by page name"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input mb-4 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            {file_id && file_id.length > 0 ? (
                file_id.map((fileData, fileIndex) => {
                    const filteredPages = filterPages(fileData.pageNames, numPages[fileIndex] || 0);
                    return (
                        <div key={`document_${fileIndex}`} className="mb-6 pdf-container">
                            <Document
                                file={fileData.url}  // Assuming fileData contains {url, pageNames}
                                onLoadSuccess={(pdf) => onDocumentLoadSuccess(fileIndex, pdf)}
                            >
                                {filteredPages.map(pageIndex => {
                                    cumulativePages++;
                                    return (
                                        <div
                                            key={`page_${fileIndex}_${pageIndex}`}
                                            className="page-container mb-4  border border-gray-200 rounded-md"
                                        >
                                            <Page
                                                pageNumber={pageIndex}
                                                renderTextLayer={false}
                                                renderAnnotationLayer={false}
                                                width={700}
                                            />
                                            <div className="flex mt-2">
                                                <p className="mr-4 ml-2 text-sm text-gray-600">
                                                    {cumulativePages} of {totalPages}
                                                </p>
                                                {/* Display page name if available */}
                                                {fileData.pageNames && fileData.pageNames[pageIndex - 1] ? (
                                                    <p className="text-sm text-gray-600">
                                                        {fileData.pageNames[pageIndex - 1]}
                                                    </p>
                                                ) : (
                                                    <p className="text-sm text-gray-400">

                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    );
                                })}
                            </Document>
                        </div>
                    );
                })
            ) : (
                <p className="text-gray-600">No PDF files available</p>
            )}
        </div>
    );
};

export default Example;
