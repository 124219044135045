import React, { useState, useEffect, useRef } from 'react';
import { Rnd } from 'react-rnd';

const DraggableComponent = ({ id }) => {
    const [position, setPosition] = useState({ x: 50, y: 200 });
    const [size, setSize] = useState({ width: "300px", height: "100px" });
    const [isFocused, setIsFocused] = useState(true); // Track whether the contenteditable div is focused
    const [text, setText] = useState(''); // Track the typed text
    const contentEditableRef = useRef(null); // Reference for contenteditable div

    // Function to place the cursor at the end of the text
    const placeCaretAtEnd = (element) => {
        const range = document.createRange();
        const selection = window.getSelection();
        range.selectNodeContents(element);
        range.collapse(false); // Collapse to the end of the range
        selection.removeAllRanges();
        selection.addRange(range);
    };

    // Reusable function to focus the contenteditable div and place the caret at the end
    const focusAndPlaceCaret = () => {
        if (contentEditableRef.current) {
            contentEditableRef.current.focus();
            placeCaretAtEnd(contentEditableRef.current); // Ensure caret is at the end
        }
    };

    // Automatically focus on the contenteditable div when the component is mounted
    useEffect(() => {
        focusAndPlaceCaret(); // Call the reusable function on mount
    }, [contentEditableRef]);

    // Handle input changes from the contenteditable div
    const handleInput = (e) => {
        setText(e.target.innerText); // Update the text state
        placeCaretAtEnd(contentEditableRef.current); // Ensure caret stays at the end
    };

    return (
        <div>
            <Rnd
                size={size}
                position={position}
                onDragStop={(e, d) => setPosition({ x: d.x, y: d.y })}
                onResizeStart={() => setIsFocused(true)}
                onResizeStop={(e, direction, ref, delta, newPosition) => {
                    setSize({ width: ref.style.width, height: ref.style.height });
                    setPosition(newPosition);
                }}
                enableResizing={{
                    top: true,
                    right: true,
                    bottom: true,
                    left: true,
                    topRight: true,
                    bottomRight: true,
                    bottomLeft: true,
                    topLeft: true,
                }} // Enable resizing in all directions
                scale={1.5} // Set scale factor
            >
                <div
                    onTouchStart={() => {
                        setIsFocused(true);
                        focusAndPlaceCaret(); // Call the reusable function on touch
                    }}
                    ref={contentEditableRef}
                    contentEditable
                    onInput={handleInput}
                    style={{
                        padding: '10px',
                        backgroundColor: 'transparent',
                        border: isFocused ? '1px dotted black' : 'none', // Show black dotted border when focused
                        height: '100%',
                        width: '100%',
                        whiteSpace: 'pre-wrap',  // Preserve line breaks and wrap text
                        wordWrap: 'break-word',
                        overflow: 'auto', // Ensure the content can scroll if it overflows
                        outline: 'none',
                        fontSize: '20px',
                        textAlign: 'left', // Ensure text starts from the left
                        direction: 'ltr', // Explicitly set left-to-right text direction
                    }}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                >
                    {text}
                </div>
            </Rnd >
        </div >
    );
};

export default DraggableComponent;
