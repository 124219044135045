import React, { useEffect, useState } from 'react';
import axiosInstance from "./axiosInterceptor";
import PdfToImage from "./PdfToImage";
import { useNavigate } from 'react-router-dom';

const ReimbursementDashboard = ({ setviewpdf }) => {
    const [reimbursements, setReimbursements] = useState([]);

    const navigate = useNavigate();

    // Fetch reimbursement data from the API
    useEffect(() => {
        const fetchReimbursements = async () => {
            try {
                const response = await axiosInstance.get('/api/formcreation/reimbursements');
                setReimbursements(response.data);
            } catch (error) {
                console.error('Error fetching reimbursement data:', error);
            }
        };

        fetchReimbursements();
    }, []);

    const handlenavigate = (urls) => {

        setviewpdf(urls);
        navigate(`/viewpdf`)

    }


    return (
        <div className="p-4">
            {/* <h1 className="text-2xl font-bold mb-4">Reimbursement Dashboard</h1> */}
            <div className="flex justify-between mb-4">
                <button className="bg-blue-200 p-2 rounded-md" onClick={() => navigate('/reimburishmentform')}>Request Reimbursement +</button>
            </div>

            <div className="overflow-x-auto">
                <table className="table-auto w-full border-collapse border border-gray-300">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="border px-4 py-2">Details</th>
                            <th className="border px-4 py-2">Bills</th>
                            <th className="border px-4 py-2">Prescriptions</th>
                            <th className="border px-4 py-2">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reimbursements.map((item) => (
                            <tr key={item._id} className="text-center">
                                <td className="border px-4 py-2">
                                    <p className="whitespace-nowrap"> {item.patient_name}</p>
                                    <p className="whitespace-nowrap">  Amount : {item.amount}</p>
                                    <p className="whitespace-nowrap">
                                        {new Date(item?.createdAt).toLocaleDateString('en-US', {
                                            day: 'numeric',
                                            month: 'short',
                                            year: 'numeric'
                                        })},{" "}
                                        {new Date(item?.createdAt).toLocaleTimeString('en-US', {
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            hour12: true
                                        })}
                                    </p>


                                </td>

                                {/* Bills */}
                                <td className="border px-4 py-2">
                                    <div className="overflow-x-auto whitespace-nowrap">
                                        <div className="flex space-x-2">
                                            {item?.bills.map((bill, billIdx) => (
                                                <div className="border rounded-md h-[200px] w-[180px]">
                                                    <img src={bill} alt="Bill preview" className="h-[180px] w-[180px]" />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </td>

                                {/* Prescriptions */}
                                <td td className="border px-4 py-2" >
                                    <div className="overflow-x-auto whitespace-nowrap">
                                        <div className="flex space-x-2">
                                            {item?.prescriptions?.map((prescription, prescriptionIdx) => (
                                                prescription?.urls?.map((urlObj, urlIdx) => (
                                                    <div key={urlIdx} className="flex flex-col items-center" onClick={() => handlenavigate(prescription?.urls)}>
                                                        <PdfToImage pdfUrl={urlObj?.url} fileId={urlObj} mainId={prescription._id}/>
                                                        <p className="whitespace-nowrap">
                                                            {urlObj?.timestamp ? new Date(urlObj?.timestamp).toLocaleDateString() : "N/A"}{" "}
                                                            {urlObj?.timestamp ? new Date(urlObj?.timestamp).toLocaleTimeString() : ""}
                                                        </p>
                                                        <p className="whitespace-nowrap">{urlObj?.doctor_name || "Unknown Doctor"}</p>
                                                    </div>
                                                ))
                                            ))}
                                        </div>
                                    </div>
                                </td>

                                {/* Status */}
                                <td className="border px-4 py-2">
                                    <span className={`px-2 py-1 rounded-full ${item.status === "Pending"
                                        ? "bg-yellow-200"
                                        : item.status === "Rejected"
                                            ? "bg-red-200"
                                            : "bg-green-200"
                                        }`}>
                                        {item.status}
                                    </span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table >
            </div>
        </div >
    );
};

export default ReimbursementDashboard;
