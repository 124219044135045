import React, { useEffect } from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';

// import Navbar from "./Navbar";
// import pdfjs from 'pdfjs-dist';


function Doctorform({ install, nextpatient, setnextpatient, fix, setfix, formData, setFormData, forms, setForms }) {

    //const lin = 'http://localhost:8000'
    const lin = 'https://dpp.baavlibuch.com'


    useEffect(() => {

        console.log("This history page is rendered");

    }, []);

    // const [templateOn, setTemplateOn] = useState(false);
    // const [qron, setqron] = useState(false)

    // const [formData, setFormData] = useState({
    //     bio: { name: "", age: "", sex: "Other", id: "", paid: false },
    //     pastSummary: "",
    //     story: "",
    //     history: { recent: "", chronic: "", acuteAndRecent: "", surgeries: "" },
    //     signsAndSymptoms: { symptoms: [""], signs: [""] },
    //     tests: [{ testName: "", result: "" }],
    //     reports: [{ reportName: "", date: "", findings: "" }],
    //     diagnosis: [{ condition: "", date: "" }],
    //     prescription: [{ medication: "", dosage: "", duration: "" }],
    //     educationprescriptionstatus: "",
    //     educationprescriptionprescription: "",
    //     referralChain: [""],
    // });



    //   const [forms, setForms] = useState();




    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData((prevState) => {
            const newState = JSON.parse(JSON.stringify(prevState)); // Deep copy of state
            const nameParts = name.split(".");

            let currentLevel = newState;
            for (let i = 0; i < nameParts.length; i++) {
                const part = nameParts[i];
                if (part.includes("[")) {
                    // Handle array fields
                    const [arrayName, arrayIndex] = part.match(/(\w+)\[(\d+)\]/).slice(1);
                    if (i === nameParts.length - 1) {
                        currentLevel[arrayName][parseInt(arrayIndex)] =
                            type === "checkbox" ? checked : value;
                    } else {
                        if (!currentLevel[arrayName]) {
                            currentLevel[arrayName] = [];
                        }
                        currentLevel = currentLevel[arrayName][parseInt(arrayIndex)];
                    }
                } else {
                    // Handle non-array fields
                    if (i === nameParts.length - 1) {
                        currentLevel[part] = type === "checkbox" ? checked : value;
                    } else {
                        if (!currentLevel[part]) {
                            currentLevel[part] = {};
                        }
                        currentLevel = currentLevel[part];
                    }
                }
            }
            return newState;
        });
    };

    const handleAddField = (fieldPath) => {
        setFormData((prevState) => {
            const newState = JSON.parse(JSON.stringify(prevState));

            switch (fieldPath) {
                case "signsAndSymptoms.symptoms":
                    newState.signsAndSymptoms.symptoms.push("");
                    break;
                case "signsAndSymptoms.signs":
                    newState.signsAndSymptoms.signs.push("");
                    break;
                case "tests":
                    newState.tests.push({ testName: "", result: "" });
                    break;
                case "reports":
                    newState.reports.push({ reportName: "", date: "", findings: "" });
                    break;
                case "diagnosis":
                    newState.diagnosis.push({ condition: "", date: "" });
                    break;
                case "prescription":
                    newState.prescription.push({
                        medication: "",
                        dosage: "",
                        duration: "",
                    });
                    break;
                case "referralChain":
                    newState.referralChain.push("");
                    break;
                default:
                    break;
            }
            return newState;
        });
    };

    // const deleteData = async () => {
    //     try {
    //         console.log(formData.bio.id);

    //         const response = await axios.delete(
    //             `${lin}/api/form/delete`,
    //             {
    //                 data: { id: formData.bio.id }, // Sending data in the request body
    //             }
    //         );

    //         // console.log(response.data); // Logging the response data
    //     } catch (error) {
    //         console.error("Error deleting data:", error);
    //     }
    // };

    // const prevpatient = (e) => {
    //     e.preventDefault();
    //     const currentIndex = forms.indexOf(formData);

    //     if (currentIndex > 0) {
    //         const prevFormData = forms[currentIndex - 1];
    //         setFormData(prevFormData);
    //     } else {
    //         alert("Patients are over");
    //     }
    // };

    const { t, i18n } = useTranslation();

    return (
        <div className="App border ">
            <form id="form" className={`form  text-xl  w-full `}>
                <div className="bio-section  ">
                    <div className="bio-tab font-bold ml-2  border">{t('history.bio')}</div>
                    <div className="bio-content flex flex-col md:flex-row gap-1 md:gap-4 p-2 flex-wrap  border">
                        <input type="text" name="bio.name" value={formData?.bio.name} onChange={handleChange} placeholder={t('history.name')} className="border w-full md:w-[150px] mb-2 md:mb-0" />
                        <input type="number" name="bio.age" value={formData?.bio.age} onChange={handleChange} placeholder={t('history.age')} className="border w-full md:w-[150px] mb-2 md:mb-0" />
                        <input type="text" name="bio.id" value={formData?.bio.id} onChange={handleChange} placeholder="Id" className="border w-full md:w-[150px]" required />
                        <div className="sex-selection">

                            <select name="bio.sex" value={formData?.bio.sex} onChange={handleChange} required className="w-full md:w-[150px] mb-2 md:mb-0" >
                                <option value="">--{t('history.sex')}--</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                            </select>
                        </div>
                        <label className="md:ml-2">
                            {t('history.paid')}:
                            <input type="checkbox" name="bio.paid" checked={formData?.bio.paid} onChange={handleChange} />
                        </label>
                    </div>
                </div>





                <div className="summary-story-wrapper border">
                    <div className="section-container past-summary-section">
                        <div className="past-summary-tab font-bold   border">{t('history.past_summary')}</div>
                        <div className="past-summary-content p-2    border">
                            <textarea name="pastSummary" value={formData?.pastSummary} onChange={handleChange} placeholder={t('history.summary_paragraph')} className="border w-full p-2" />
                        </div>
                    </div>
                    <div className="section-container story-section border">
                        <div className="story-tab font-bold ml-2   border ">{t('history.story')}</div>
                        <div className="past-summary-content  p-2   border">
                            <textarea name="story" value={formData?.story} onChange={handleChange} placeholder={t('history.own_words')} className="border w-full p-2" />
                        </div>
                    </div>
                </div>

                <div className="history-section border">
                    <div className="history-tab font-bold ml-2">{t('history.history')}</div>
                    <div className="history-content p-2 border flex justify-between flex-wrap ml-4 mr-4">
                        <label className="">
                            <textarea name="history.recent" value={formData?.history?.recent} onChange={handleChange} placeholder={t('history.recent')} className="border p-2" cols={30} />
                        </label>
                        <label className="">
                            <textarea name="history.chronic" value={formData?.history?.chronic} onChange={handleChange} placeholder={t('history.chronic_history')} className="border p-2" cols={30} />
                        </label>
                        <label className="">
                            <textarea name="history.acuteAndRecent" value={formData?.history?.acuteAndRecent} onChange={handleChange} placeholder={t('history.acute_recent_history')} className="border p-2" cols={30} />
                        </label>
                        <label className="">
                            <textarea name="history.surgeries" value={formData?.history?.surgeries} onChange={handleChange} placeholder={t('history.surgeries')} className="border p-2 " cols={30} />
                        </label>
                    </div>
                </div>

                <div className="signs-symptoms-section border flex flex-wrap md:flex-nowrap">
                    <div className="symptoms-section w-full p-2">
                        <div className="symptoms-tab font-bold ml-2">{t('history.reported_symptoms')}</div>
                        {formData?.signsAndSymptoms.symptoms.map((symptom, index) => (
                            <input key={`symptom-${index}`} type="text" className="symptoms-input border w-full p-2 h-10" name={`signsAndSymptoms.symptoms[${index}]`} value={symptom} onChange={handleChange} placeholder={`${t('history.reported_symptoms')} #${index + 1}`} />
                        ))}
                        <button type="button" className="add-button" onClick={() => handleAddField("signsAndSymptoms.symptoms")}>
                            <img className="h-2 w-2 ml-2" src="./add.png" alt="Add Button" />
                        </button>
                    </div>

                    <div className="signs-section w-full p-2">
                        <div className="signs-tab font-bold ml-2">{t('history.signs')}</div>
                        {formData?.signsAndSymptoms.signs.map((sign, index) => (
                            <input key={`sign-${index}`} type="text" className="signs-input  border w-full p-2 h-10" name={`signsAndSymptoms.signs[${index}]`} value={sign} onChange={handleChange} placeholder={`${t('history.signs')} #${index + 1}`} />
                        ))}
                        <button type="button" className="add-button" onClick={() => handleAddField("signsAndSymptoms.signs")}>
                            <img className="h-2 w-2 ml-2" src="./add.png" alt="Add Button" />
                        </button>
                    </div>
                </div>

                <div className="all-sections-container">
                    <div className="tests-reports-section flex justify-between flex-wrap md:flex-nowrap">
                        <div className="tests-container border w-full">
                            <div className="signs-tab ml-2 font-bold">{t('history.tests')}</div>
                            {formData?.tests.length === 0 ? (
                                <div key={`test-`} className="test-entry">
                                    <input type="text" name={`tests[$].testName`} value={""} onChange={handleChange} placeholder={t('history.test_name')} className="ml-1 border" />
                                    <input type="text" name={`tests[$].result`} value={""} onChange={handleChange} placeholder={t('history.result')} className="border ml-1 mr-1 w-[300px]" />
                                </div>
                            ) : (
                                formData?.tests.map((test, index) => (
                                    <div key={`test-${index}`} className="test-entry">
                                        <input type="text" name={`tests[${index}].testName`} value={test.testName} onChange={handleChange} placeholder={t('history.test_name')} className="ml-1 border" />
                                        <input type="text" name={`tests[${index}].result`} value={test.result} onChange={handleChange} placeholder={t('history.result')} className="border ml-1 mr-1 w-[300px]" />
                                    </div>
                                ))
                            )}
                            <button type="button" onClick={() => handleAddField("tests")}>
                                <img className="h-2 w-2 ml-2" src="./add.png" alt="Add Button" />
                            </button>
                        </div>

                        <div className="reports-container border w-full">
                            <div className="signs-tab font-bold ml-2">{t('history.reports')}</div>
                            {formData?.reports.length === 0 ? (
                                <div key={`report-$`} className="report-entry">
                                    <input type="text" name={`reports[$].reportName`} value={""} onChange={handleChange} placeholder={t('history.report_name')} className="ml-1 border" />
                                    <input type="string" name={`reports[$].date`} value={""} onChange={handleChange} placeholder={t('history.date')} className="ml-1 border" />
                                    <input rows={2} name={`reports[$].findings`} value={""} onChange={handleChange} placeholder={t('history.findings')} className="border ml-1 mr-1 w-[300px]" />
                                </div>
                            ) : (
                                formData?.reports.map((report, index) => (
                                    <div key={`report-${index}`} className="report-entry">
                                        <input type="text" name={`reports[${index}].reportName`} value={report.reportName} onChange={handleChange} placeholder={t('history.report_name')} className="ml-1 border" />
                                        <input type="string" name={`reports[${index}].date`} value={report.date} onChange={handleChange} placeholder={t('history.date')} className="ml-1 border" />
                                        <input rows={2} name={`reports[${index}].findings`} value={report.findings} onChange={handleChange} placeholder={t('history.findings')} className="border ml-1 mr-1 w-[300px]" />
                                    </div>
                                ))
                            )}
                            <button type="button" onClick={() => handleAddField("reports")}>
                                <img className="h-2 w-2 ml-2" src="./add.png" alt="Add Button" />
                            </button>
                        </div>
                    </div>

                    <div className="tests-reports-section flex flex-wrap md:flex-nowrap">
                        <div className="reports-container border w-full">
                            <h3 className="signs-tab font-bold ml-2">{t('history.diagnosis')}</h3>
                            {formData?.diagnosis.map((diag, index) => (
                                <div key={`diagnosis-${index}`} className="diagnosis-entry">
                                    <input type="text" name={`diagnosis[${index}].condition`} value={diag.condition} onChange={handleChange} placeholder={t('history.diagnosis')} className="ml-1 border" />
                                    <input type="string" name={`diagnosis[${index}].date`} value={diag.date} onChange={handleChange} placeholder={t('history.date')} className="ml-1 border" />
                                </div>
                            ))}
                            <button type="button" onClick={() => handleAddField("diagnosis")}>
                                <img className="h-2 w-2 ml-2" src="./add.png" alt="Add Button" />
                            </button>
                        </div>

                        <div className="reports-container border w-full">
                            <h3 className="signs-tab font-bold ml-2">{t('history.prescription')}</h3>
                            {formData?.prescription.map((pres, index) => (
                                <div key={`prescription-${index}`} className="prescription-entry">
                                    <input type="text" name={`prescription[${index}].medication`} value={pres.medication} onChange={handleChange} placeholder="Medication" className="ml-1 border" />
                                    <input type="text" name={`prescription[${index}].duration`} value={pres.duration} onChange={handleChange} placeholder={t('history.date')} className="ml-1 border" />
                                    <input type="text" name={`prescription[${index}].dosage`} value={pres.dosage} onChange={handleChange} placeholder="Dosage" className="border ml-1 mr-1 w-[300px]" />
                                </div>
                            ))}
                            <button type="button" onClick={() => handleAddField("prescription")}>
                                <img className="h-2 w-2 ml-2" src="./add.png" alt="Add Button" />
                            </button>
                        </div>
                    </div>
                </div>

                <div className="section-container past-summary-section border">
                    <div className="past-summary-tab font-bold ml-2">{t('history.patient_education_status')}</div>
                    <div className="past-summary-content flex border p-2">
                        <textarea cols={40} rows={1} name="educationprescriptionstatus" value={formData?.educationprescriptionstatus} onChange={handleChange} placeholder={t('history.status')} className="ml-1 border p-2" />
                        <textarea cols={100} rows={1} name="educationprescriptionprescription" value={formData?.educationprescriptionprescription} onChange={handleChange} placeholder={t('history.prescription')} className="border p-2" />
                    </div>
                </div>

                <div className="reports-container border">
                    <h3 className="signs-tab font-bold ml-2 border">{t('history.referral_chain')}</h3>
                    {formData?.referralChain.map((referral, index) => (
                        <input key={`referral-${index}`} className="m-2 border" type="text" name={`referralChain[${index}]`} value={referral} onChange={handleChange} placeholder={`${t('history.referral_chain')} #${index + 1}`} />
                    ))}
                    <button type="button" onClick={() => handleAddField("referralChain")}>
                        <img className="h-2 w-2 ml-2" src="./add.png" alt="Add Button" />
                    </button>

                </div>



            </form >

        </div >
    );
}



export default Doctorform;
