import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosInterceptor';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import PdfToImage from '../PdfToImage';
import { useTranslation } from 'react-i18next';

const Pdf = ({ qrUrl, formData, setviewpdf }) => {
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const { doctorId } = useParams();
    console.log(doctorId)
    const [pdfUrls, setPdfUrls] = useState([]);
    const [selectedTab, setSelectedTab] = useState('new');

    const getPdfUrls = async () => {
        const response = await axiosInstance.get(`/api/form/pdf-urls/${doctorId}`);
        console.log(response.data)
        return response.data;
    };

    const handlenavigate = (urls) => {

        setviewpdf([urls]);
        navigate(`/viewpdf`)

    }

    useEffect(() => {
        let intervalId;
        const fetchPdfUrls = async () => {
            const filteredPdfUrls = await getPdfUrls();
            const urls = filteredPdfUrls;
            if (urls && urls.length > 0 && JSON.stringify(urls) !== JSON.stringify(pdfUrls)) {
                console.log(urls)
                setPdfUrls(urls);
                clearInterval(intervalId);
            }
        };


        intervalId = setInterval(fetchPdfUrls, 3000);

        return () => clearInterval(intervalId);
    }, []);

    const handleDownload = async (pdfUrl) => {
        const today = new Date();
        const year = today.getFullYear().toString().padStart(2, '0');
        const month = (today.getMonth() + 1).toString().padStart(2, '0');
        const day = today.getDate().toString().padStart(2, '0');
        const hours = today.getHours().toString().padStart(2, '0');
        const minutes = today.getMinutes().toString().padStart(2, '0');

        const filename = `${year}${month}${day}_${hours}${minutes}_${localStorage.getItem("user_name")}_${formData?.bio?.name}.pdf`;
        console.log(pdfUrl)
        const secureUrl = pdfUrl?.replace(/^http:/, 'https:');
        console.log(secureUrl);
        try {
            const response = await fetch(secureUrl);
            const blob = await response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute("download", filename); // Set the filename for the download
            document.body.appendChild(link);
            link.click();
            link.remove();

            // Release the object URL after the download
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Failed to download PDF:', error);
        }
    };

    return (
        <div>
            {pdfUrls?.length > 0 ? <div className="tab-content">
                {selectedTab === 'new' ? (
                    <div className="tab-pane">
                        <table>
                            <thead>
                                <tr>
                                    <th>{t('details')}</th>
                                    <th>{t('files')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <td> {pdfUrls[0]?.patient_name}</td>
                                {/* <td>{pdfUrls
                                    ?.sort((a, b) => b.timestamp - a.timestamp)
                                    ?.slice(-1)
                                    ?.map((pdfUrl) => (
                                        <tr key={pdfUrl.timestamp}>
                                            <td>{new Date(pdfUrl?.urls[0].timestamp).toLocaleDateString()} {new Date(pdfUrl?.urls[0].timestamp).toLocaleTimeString()}</td>
                                            <td>{pdfUrl?.patient_name}</td>
                                          
                                             
                                {pdfUrl?.urls[0] ? (
                                    <div onClick={() => handlenavigate(pdfUrl?.urls)}>
                                        <PdfToImage pdfUrl={pdfUrl?.urls[pdfUrl?.urls?.length - 1]?.url} />
                                    </div>
                                ) : (
                                    ''
                                )}
                            </td>
                        </tr>
                                    ))}</td> */}

                                <td> {pdfUrls[0]?.files ? (
                                    <div onClick={() => handlenavigate(pdfUrls[0]?.files?.urls)}>
                                        <PdfToImage pdfUrl={pdfUrls[0]?.files?.urls?.url} fileId={pdfUrls[0]?.files}/>
                                        <p className="whitespace-nowrap">  {new Date(pdfUrls[0]?.files?.urls?.timestamp).toLocaleDateString()} {new Date(pdfUrls[0]?.files?.urls?.timestamp).toLocaleTimeString()}</p>
                                        <p className="whitespace-nowrap"> {pdfUrls[0]?.files?.urls?.doctor_name}</p>
                                    </div>
                                ) : (
                                    ''
                                )}</td>
                            </tbody>
                        </table>
                    </div >
                ) : (
                    <div className="tab-pane">
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Time</th>
                                    <th>Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pdfUrls
                                    ?.sort((a, b) => b.timestamp - a.timestamp)
                                    ?.slice(1)
                                    ?.map((pdfUrl) => (
                                        <tr key={pdfUrl.timestamp}>
                                            <td>{new Date(pdfUrl.timestamp).toLocaleDateString()}</td>
                                            <td>{new Date(pdfUrl.timestamp).toLocaleTimeString()}</td>
                                            <td>
                                                <button
                                                    className="download-btn"
                                                    onClick={() => handleDownload(pdfUrl.url)}
                                                >
                                                    Download PDF
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div > : <div className="tab-content"> PDF is generating </div>}
        </div >
    );
};

export default Pdf;
