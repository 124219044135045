import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosInterceptor';
import { useNavigate, useParams } from 'react-router-dom';
import * as pdfjsLib from 'pdfjs-dist';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { v4 as uuidv4 } from 'uuid';
const FormFiller = ({ fileId, setfileId }) => {
  const { id } = useParams();
  const [form, setForm] = useState(null);
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const [showattachedFiles, setShowAttachedFiles] = useState();
  const [fileData, setFileData] = useState(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log(fileId)

      if (fileId === '') {
        navigate('/');
      }
    }, 2000);
    return () => clearTimeout(timer);
  }, [fileId]);

  const renderPdfAsImages = async (file) => {
    const reader = new FileReader();
    return new Promise((resolve) => {
      reader.onload = async () => {
        const typedArray = new Uint8Array(reader.result);
        const pdf = await pdfjsLib.getDocument(typedArray).promise;
        const pages = [];

        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const viewport = page.getViewport({ scale: 1.5 });
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');
          canvas.height = viewport.height;
          canvas.width = viewport.width;

          await page.render({ canvasContext: context, viewport }).promise;
          pages.push(canvas.toDataURL());
        }

        resolve(pages);
      };
      reader.readAsArrayBuffer(file);
    });
  };

  useEffect(() => {
    const fetchForm = async () => {
      try {
        const response = await axiosInstance.get(`/api/formcreation/form/${id}`);
        console.log(response.data);
        setForm(response.data);

        const initialData = {};
        response.data.formFields.forEach(field => {
          initialData[field.label] = '';
        });
        setFormData(initialData);

        const attachedFilePaths = `${process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000'}/${response.data.attachedFiles}`;
        console.log(attachedFilePaths);

        try {
          const fileResponse = await fetch(attachedFilePaths);
          const blob = await fileResponse.blob();
          const file = new File([blob], response.data.attachedFiles, { type: blob.type });

          let filePreview;
          console.log("File type:", file.type);

          if (file.type === 'application/pdf') {
            const pages = await renderPdfAsImages(file);
            filePreview = { file, preview: pages };
          } else {
            const reader = new FileReader();
            filePreview = await new Promise((resolve) => {
              reader.onload = () => {
                resolve({ file, preview: [reader.result] });
              };
              reader.readAsDataURL(file);
            });
          }

          setShowAttachedFiles(filePreview);
        } catch (fileError) {
          console.error("Error fetching the file:", fileError);
        }
      } catch (error) {
        console.error('Error fetching form:', error);
      }
    };

    fetchForm();
  }, [id]);



  const uploadFileToBackend = async (file, filename) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('filename', filename);

    try {
      const response = await axiosInstance.post('/api/form/uploadfiles', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(response.data.url)
      return response.data.url;
    } catch (error) {
      console.error('Failed to upload file', error);
      throw new Error('Failed to upload file');
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox' || type === 'radio') {
      setFormData({ ...formData, [name]: checked });
    } else if (type === 'file') {
      setFileData(e.target.files[0]); // Handle file input separately
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const input = document.getElementById('pdf-container');
    const originalStyles = input.style.cssText;
    input.style.padding = '20px';
    input.style.margin = '10px';
    input.style.marginLeft = '25.4mm';
    input.style.width = '210mm';
    input.style.height = '297mm';
    input.style.fontFamily = 'Arial, sans-serif';
    input.style.fontSize = '12px';

    const inputs = input.querySelectorAll('input, textarea, select');
    inputs.forEach(el => {
      el.style.border = 'none';
      el.style.outline = 'none';
      el.style.boxShadow = 'none';
      el.style.marginBottom = '10px';
    });

    // Lower the canvas scale to reduce file size
    const canvas = await html2canvas(input, {
      scale: 1,  // Reduced scale for smaller PDF
      width: 800,
      windowWidth: 800,
    });

    const imgData = canvas.toDataURL('image/jpeg', 0.7);  // Using JPEG with compression
    const pdf = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      format: 'a4',
      compressPdf: true,  // Compress the PDF
    });

    const imgWidth = 210;
    const imgHeight = canvas.height * imgWidth / canvas.width;

    pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight, undefined, 'FAST');

    const pdfBlob = pdf.output('blob');
    input.style.cssText = originalStyles;

    const data = new FormData();
    data.append('formData', JSON.stringify(formData));

    if (fileData) {
      data.append('file', fileData);
    }

    try {
      await axiosInstance.post(`/api/formcreation/submit/${id}`, data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const uniqueFileName = `form_${uuidv4()}.pdf`;
      const cloudinaryUrl = await uploadFileToBackend(pdfBlob, uniqueFileName);

      const datainfo = {
        fileId: fileId,
        url: cloudinaryUrl,
        pageCount: 1,
        pageNames: ["Attachfile"],
        doctor_id: " ",
        doctor_name: " "
      }

      await axiosInstance.put(`/api/form/patient-files/attachfile`, datainfo);

      navigate('/form-confirmation');
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };



  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   const data = new FormData();
  //   data.append('formData', JSON.stringify(formData));
  //   if (fileData) {
  //     data.append('file', fileData);
  //   }
  //   try {
  //     await axiosInstance.post(`/api/formcreation/submit/${id}`, data, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     });
  //     navigate('/form-confirmation');
  //   } catch (error) {
  //     console.error('Error submitting form:', error);
  //   }
  // };

  const renderField = (field, index) => {
    switch (field.type) {
      case 'label':
        return (
          <div key={index} className="mb-4">
            <h1 className="block text-gray-700">
              {field.label} {field.required && <span className="text-red-500">*</span>}
            </h1>
            <p className='text-gray-600 text-sm mb-1'>{field.desc}</p>
          </div>
        );
      case 'text':
        return (
          <div key={index} className="mb-4">
            <label className="block text-gray-700">
              {field.label} {field.required && <span className="text-red-500">*</span>}
            </label>
            <p className='text-gray-600 text-sm mb-1'>{field.desc}</p>
            <input
              className="w-full p-2 border border-gray-300 rounded h-12"
              type="text"
              name={field.label}
              value={formData[field.label] || ''}
              onChange={handleChange}
              required={field.required}
            />
          </div>
        );
      case 'checkbox':
        return (
          <div key={index} className="mb-4">
            <p className='text-gray-600 text-sm mb-1'>{field.desc}</p>
            <label className="block text-gray-700">
              <input
                className="mr-2"
                type="checkbox"
                name={field.label}
                checked={formData[field.label] || false}
                onChange={handleChange}
                required={field.required}
              />
              {field.label} {field.required && <span className="text-red-500">*</span>}
            </label>
          </div>
        );
      case 'radio':
        return (
          <div key={index} className="mb-4">
            <p className='text-gray-600 text-sm mb-1'>{field.desc}</p>
            <label className="block text-gray-700">
              <input
                className="mr-2"
                type="radio"
                name={field.label}
                checked={formData[field.label] || false}
                onChange={handleChange}
                required={field.required}
              />
              {field.label} {field.required && <span className="text-red-500">*</span>}
            </label>
          </div>
        );
      case 'file':
        return (
          <div key={index} className="mb-4">
            <p className='text-gray-600 text-sm mb-1'>{field.desc}</p>
            <label className="block text-gray-700">
              {field.label} {field.required && <span className="text-red-500">*</span>}
            </label>
            <input
              className="w-full p-2 border border-gray-300 rounded"
              type="file"
              name={field.label}
              onChange={handleChange}
              required={field.required}
            />
          </div>
        );
      default:
        return null;
    }
  };

  if (!form) return <div>Loading...</div>;

  return (
    <div className="bg-white p-4 border border-gray-200 max-w-screen-md mx-auto">
      < div id="pdf-container" >
        {form.formType.title && <h1 className="text-lg font-bold text-center">{form.formType.title}</h1>}
        {form.formType.desc && <p className='text-gray-600 text-sm text-center'>{form.formType.desc}</p>}
        {
          showattachedFiles && (
            <div className="mb-4 w-full">
              <div className="mb-2 w-full grid justify-items-center">
                {showattachedFiles.file.type.startsWith('image/') ? (
                  <img src={showattachedFiles.preview[0]} alt="File preview" className="max-w-full" />
                ) : showattachedFiles.file.type === 'application/pdf' ? (
                  <div className='w-full grid justify-center'>
                    {showattachedFiles.preview.map((page, pageIndex) => (
                      <img key={pageIndex} src={page} alt={`PDF page ${pageIndex + 1}`} className="mb-2 max-w-full" />
                    ))}
                  </div>
                ) : (
                  <a href={showattachedFiles.preview[0]} download={showattachedFiles.file.name} className="text-blue-500">
                    {showattachedFiles.file.name}
                  </a>
                )}
              </div>
            </div>
          )
        }

        <form className="space-y-4">
          {form.formFields.map((field, index) => renderField(field, index))}
        </form>
      </ div>

      <button className="bg-green-500 text-white p-2 rounded mt-4" type="submit" onClick={handleSubmit}>
        Submit
      </button>
    </div>
  );
};

export default FormFiller;
