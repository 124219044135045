import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import axiosInstance from './axiosInterceptor';
import { MdConstruction } from 'react-icons/md';

const Roles = () => {

  const navigate = useNavigate();
  const [selectedRole, setSelectedRole] = useState('');
  const [errorMessage, setErrorMessage] = useState('')

  const handleRoleSelection = (event) => {
    setSelectedRole(event.target.value);
    if (event.target.value === 'Doctor') {
      console.log('Doctor');
    }
  };

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    const tokenId = credentialResponse.credential;
    console.log('Google Login Success:', tokenId);
    try {
      const decodedToken = jwtDecode(tokenId);
      console.log(decodedToken)
      const { email, name } = decodedToken;

      if (!selectedRole) {
        setErrorMessage('Role not selected');
        console.error('Role not selected');
        return;
      }

      // Backend API call to save user data with selected role
      const response = await axiosInstance.post(`/api/form/google-login`, {
        email,
        name,
        role: selectedRole,
        googleToken: tokenId
      });
      console.log('Google Login Response:', response.data);

      if (response.status === 200) {
        console.log('Login Successful:', response.data);
        // Store the token in localStorage
        localStorage.setItem('user_name', response.data.name);
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user_role', response.data.role);
        localStorage.setItem('user_email', response.data.email);
        localStorage.setItem('user_login', response.data.userId);

        // Navigate to home page or dashboard upon successful login
        navigate('/');
        window.location.reload();
      } else if (response.status === 400) {
        setErrorMessage(response.data.message || 'Invalid login request');
        console.error('Login failed:', response.data.message);
      } else {
        setErrorMessage(response.data.message || 'Login failed');
        console.error('Login failed:', response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        setErrorMessage(error.response.data.message || 'Invalid login request');
      } else {
        setErrorMessage('An error occurred during login');
      }
      console.error('Error during login:', error);
    }
  };


  const handleGoogleLoginError = () => {
    console.log('Google Login Failed');
    setErrorMessage('Google Login Failed');
  };

  return (
    <div className='flex flex-col justify-center text-center items-center '>
      <div className="m-10">
        <h2 className="text-2xl font-semibold mb-4">Select Role:</h2>
        <select
          value={selectedRole}
          onChange={handleRoleSelection}
          className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:ring-blue-200 focus:border-blue-500"
        >
          <option value="">Select a role</option>
          <option value="Doctor">Doctor</option>
          <option value="Hospital">Hospital</option>
          <option value="Non-Medical">NonMedical</option>
        </select>
      </div>



      <GoogleLogin
        clientId="65544981834-6htji6av6sr71504gg6096cmvhn9fe4v.apps.googleusercontent.com"
        scope='https://www.googleapis.com/auth/user.birthday.read https://www.googleapis.com/auth/user.gender.read https://www.googleapis.com/auth/userinfo.profile'
        onSuccess={handleGoogleLoginSuccess}
        onError={handleGoogleLoginError}
      />
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      <div className="flex justify-center items-center my-4">
        <button
          onClick={() => navigate('/login')}
          className="flex items-center bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
        >
          <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18"></path>
          </svg>
          Back
        </button>
      </div>

    </div>
  );
};

export default Roles;

