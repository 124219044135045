import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

const PageQR = ({ url_id, setUuid, uuid, setQrCodeUrl, formdata }) => {

    const [url, setUrl] = useState('');

    const baseUrl = process.env.REACT_APP_BASE_URL || 'https://dpp.baavlibuch.com';

    // Function to generate QR Code URL
    const generateQRCode = (dataUrl) => {
        const encodedUrl = encodeURIComponent(dataUrl);
        return `https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${encodedUrl}`;
    };

    useEffect(() => {

        const timeoutId = setTimeout(() => {
            const newUuid = uuidv4();
            const newUrl = `${baseUrl}/fileqr/${newUuid}`;
            console.log("the generated url", newUrl)
            setUrl(newUrl);
            const generatedQRCodeUrl = generateQRCode(newUrl);
            setQrCodeUrl(generatedQRCodeUrl);
            console.log(newUrl);
            setUuid(newUuid);
        }, 0);

        // Cleanup the timeout if the component is unmounted or if `url_id` changes
        return () => clearTimeout(timeoutId);

    }, [url_id, formdata]); // Add uuid to the dependency array


    return (
        <span className="h-[180px] w-[180px] bg-transparent">
        </span>
    );
};

export default PageQR;
