import React, { useEffect, useState } from 'react';
import axiosInstance from './axiosInterceptor';
import PdfToImage from './PdfToImage';
import { useNavigate } from 'react-router-dom';

const DashboardTable = ({ setviewpdf }) => {
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const [selectedBill, setSelectedBill] = useState(null); // Track selected bill for modal
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Fetch the data from the API
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosInstance.get('/api/formcreation/reimbursements'); // Adjust the API endpoint as per your requirements
                setData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const handlenavigate = (urls) => {

        setviewpdf(urls);
        navigate(`/viewpdf`)

    }

    const openModal = (bill) => {
        setSelectedBill(bill);
        setIsModalOpen(true);
    };

    // Function to close modal
    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedBill(null);
    };

    const handleStatusUpdate = async (itemId, newStatus) => {
        try {
            await axiosInstance.patch(`/api/formcreation/reimbursements/${itemId}/status`, { status: newStatus });
            setData(prevData => prevData.map(item =>
                item._id === itemId ? { ...item, status: newStatus } : item
            ));
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };
    return (
        <div className="container mx-auto p-4">
            <div className="flex justify-between mb-4">
                <button className="bg-blue-100 text-blue-500 py-2 px-4 rounded">Create form</button>
            </div>

            <div className="overflow-x-auto">
                <table className="table-auto w-full border-collapse bg-gray-100">
                    <tbody>
                        {data?.map((item, idx) => (
                            <tr key={idx} className="border border-gray-200">
                                {/* Patient Info and Questions */}
                                <td className="w-1/4 p-4">
                                    <div className="mb-2 font-bold"> {item.patient_name}</div>
                                    <p className="whitespace-nowrap">  Amount : {item.amount}</p>
                                    <p className="whitespace-nowrap">
                                        {new Date(item?.createdAt).toLocaleDateString('en-US', {
                                            day: 'numeric',
                                            month: 'short',
                                            year: 'numeric'
                                        })},{" "}
                                        {new Date(item?.createdAt).toLocaleTimeString('en-US', {
                                            hour: 'numeric',
                                            minute: 'numeric',
                                            hour12: true
                                        })}
                                    </p>
                                    {/* <div>{item.question1} : {item.answer1}</div>
                                    <div>{item.question2} : {item.answer2}</div>
                                    <div>{item.question3} : {item.answer3}</div> */}
                                </td>

                                {/* Files (Bills and Prescriptions) */}



                                <td className="p-4">
                                    <div className="overflow-x-auto whitespace-nowrap">
                                        <div className="flex space-x-2">
                                            {item?.bills.map((bill, billIdx) => (
                                                <div
                                                    key={billIdx}
                                                    className="border rounded-md h-[200px] w-[180px] cursor-pointer"
                                                    onClick={() => openModal(bill)}
                                                >
                                                    <img src={bill} alt="Bill preview" className="h-[180px] w-[180px]" />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </td>

                                <td className="p-4">
                                    <div className="overflow-x-auto whitespace-nowrap">
                                        <div className="flex space-x-2">
                                            {item?.prescriptions?.map((prescription, prescriptionIdx) => (
                                                prescription?.urls?.map((urlObj, urlIdx) => (
                                                    <div key={urlIdx} className="flex flex-col items-center" onClick={() => handlenavigate(prescription?.urls)}>
                                                        <PdfToImage pdfUrl={urlObj?.url} fileId={urlObj} mainId={prescription._id}/>
                                                        <p className="whitespace-nowrap">
                                                            {urlObj?.timestamp ? new Date(urlObj?.timestamp).toLocaleDateString() : "N/A"}{" "}
                                                            {urlObj?.timestamp ? new Date(urlObj?.timestamp).toLocaleTimeString() : ""}
                                                        </p>
                                                        <p className="whitespace-nowrap">{urlObj?.doctor_name || "Unknown Doctor"}</p>
                                                    </div>
                                                ))
                                            ))}
                                        </div>
                                    </div>
                                </td>

                                {/* Action Buttons (Approval and Edit) */}
                                <td td className="w-1/6 p-4" >
                                    <div className="mb-2">
                                        <span className={`text-xs font-semibold inline-block py-1 px-2 rounded text-white ${item.status === 'Accepted' ? 'bg-green-500' : item.status === 'Rejected' ? 'bg-red-500' : 'bg-yellow-500'}`}>
                                            {item.status || 'Pending'}
                                        </span>
                                    </div>
                                    <div className="flex items-center justify-between mb-2">
                                        <button
                                            className="text-green-500"
                                            onClick={() => handleStatusUpdate(item._id, 'Accepted')}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                className="w-6 h-6"
                                            >
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                            </svg>
                                        </button>
                                        <button
                                            className="text-red-500"
                                            onClick={() => handleStatusUpdate(item._id, 'Rejected')}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                                strokeWidth="1.5"
                                                stroke="currentColor"
                                                className="w-6 h-6"
                                            >
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </div>
                                    <button className="bg-blue-100 text-blue-500 py-2 px-4 rounded">Edit</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>


            </div >
        </div >
    );
};

export default DashboardTable;
