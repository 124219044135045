import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Doctorform from "./Doctorform";
import { socketClient } from "./socket";
import Doctorsform from "./Doctorsform";
import Qrcode from "./Qrcode";
import React, { useState, useEffect, useRef } from "react";
import NewPatient from "./NewpatientForm";
import Login from "./Component/Login";
import Register from "./Component/Register";
import Billing from "./Billing";
import Navbar from "./Navbar";
import Pdf from "./Component/Pdf";
import SearchBar from "./Component/SearchBar";
import Roles from "./Roles";
import Upload from "./Upload";
import Scanner from "./Scanner";
import ProtectedRoute from "./ProtectedRoutes"; // Import the ProtectedRoute component
import Receptionist from "./Receptionist";
import axiosInstance from "./axiosInterceptor";
import Example from "./PdfViewer";
import Report from "./Component/Report";
import Doctorsidereport from "./Component/Doctorsidereport";
import Doctorsiderfile from "./Component/Doctorsidefile";
import Receptionistregistration from "./Component/Receptionistregistration";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import { Store } from "react-notifications-component";
import { ReactNotifications } from "react-notifications-component";
import Hospital from "./Component/Hospital";
import DragResizeExample from "./Component/Typing";
import FormCreation from "./Formcreation";
import FormFiller from "./FormFiller";
import ConfirmationPage from "./Confirmation";
import VerifyFile from "./Component/verifyFile";
import Dashboard from "./Dashboard";
import ReimbursementDashboard from "./reimburishmentdashboard";
import ReimbursementForm from "./remburishment";
const { register } = require("./serviceWorker");

function App() {
  // const location = useLocation();
  // const shouldRenderNavbar = !location.pathname.startsWith('/form-filler/');

  window.addEventListener("beforeinstallprompt", (event) => {
    event.preventDefault();
    window.deferredPrompt = event;
    return true;
  });

  const [qrUrl, setQrUrl] = useState("");
  const [nextpatient, setnextpatient] = useState("none");
  const [fix, setfix] = useState(0);
  const [forms, setForms] = useState([]);
  const [viewpdf, setviewpdf] = useState([]);
  const [audio, setaudio] = useState(1);
  const [currentPath, setcurrentPath] = useState("");
  const [fileId, setfileId] = useState("");
  const [url_id, seturl_id] = useState(" ");
  const [formData, setFormData] = useState({
    bio: { name: "", age: "", sex: "", id: "", paid: false },
    pastSummary: "",
    story: "",
    history: { recent: "", chronic: "", acuteAndRecent: "", surgeries: "" },
    signsAndSymptoms: { symptoms: [""], signs: [""] },
    tests: [{ testName: "", result: "" }],
    reports: [{ reportName: "", date: "", findings: "" }],
    diagnosis: [{ condition: "", date: "" }],
    prescription: [{ medication: "", dosage: "", duration: "" }],
    educationprescriptionstatus: "",
    educationprescriptionprescription: "",
    referralChain: [""],
  });

  const [uuid, setUuid] = useState("");

  useEffect(() => {
    // Attach the listener
    socketClient.on("msg-patient", (data) => {
      if (data?.updatedForm?.doctor_ID == localStorage.getItem("user_login")) {
        Store.addNotification({
          title: "Patient +1",
          type: "info",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
          },
        });
        if (forms?.length > 0) {
          setForms((prevForms) => [...prevForms, data?.updatedForm]);
        } else {
          setForms([data?.updatedForm]);
          setFormData(data?.updatedForm);
        }
      }
    });

    // Cleanup listener on unmount
    return () => {
      socketClient.off("msg-patient");
    };
  }, []); // Empty dependency array ensures it only runs once

  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err)
  );

  const addAudioElement = (blob) => {
    const url = URL.createObjectURL(blob);
    const audio = document.createElement("audio");
    audio.src = url;
    audio.controls = true;
    document.body.appendChild(audio);
  };

  useEffect(() => {
    conectChat();
  }, []);

  const conectChat = () => {
    const currentUser = localStorage.getItem("user_login");
    try {
      if (currentUser) {
        console.log(socketClient);
        socketClient.emit("add-user", currentUser);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     recorderControls.startRecording();
  //   }, 5000);
  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // }, [audio]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/form/bio/${localStorage.getItem("user_login")}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`, //
            },
          }
        );
        console.log(response.data);
        setForms(response.data);
        setFormData(response.data[0]);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    // if (fix === 0)
    fetchData(); // Invoke the async function
    // setfix(1);
  }, []);

  async function install(event) {
    if (window.deferredPrompt !== null) {
      console.log(window.deferredPrompt);
      window.deferredPrompt?.prompt();
      console.log("deferredPrompt await begins");
      const outcome = await window.deferredPrompt?.userChoice;
      if (outcome === "accepted") {
        console.log("deferred prompt accepted");
        window.deferredPrompt = null;
      } else console.log("deferred prompt outcome = ", outcome);
    } else console.log("deferred prompt is null");
  }

  const [isMounted, setIsMounted] = useState(false);
  const [checkedTemplates, setCheckedTemplates] = useState([]);
  const buttonRef = useRef(null);
  const [pageNames, setpageNames] = useState([]);
  const [stamp, setstamp] = useState([]);
  const [qrCodeUrl, setQrCodeUrl] = useState("");

  const allowedRoutes = [
    "/handwritten",
    // "/history",
    // "/search",
    // "/reception",
    // "/doctorsidereport",
    // "/doctorsidefile",
  ];
  const shouldRenderRecorder = allowedRoutes.some((route) =>
    new RegExp(`^${route.replace(":patient_id", "[^/]+")}$`).test(currentPath)
  );

  return (
    <>
      <Router>
        {/* {shouldRenderNavbar && <Navbar install={install} setcurrentPath={setcurrentPath} />} */}
        <Navbar install={install} setcurrentPath={setcurrentPath} />
        <ReactNotifications />
        <div className="flex">
          {shouldRenderRecorder && (
            <div
              // style={{ position: "absolute", top: "45vh", left: "10px" }}
              className="z-10 relative top-44 left-2"
            >
              <AudioRecorder
                onRecordingComplete={(blob) => addAudioElement(blob)}
                recorderControls={recorderControls}
                showVisualizer={true}
                downloadFileExtension="webm"
              />
              <button
                ref={buttonRef}
                className="hidden"
                onClick={() => {
                  recorderControls.stopRecording();
                }}
              >
                Stop recording
              </button>
            </div>
          )}
        </div>
        <Routes>
          <Route
            path="/form-filler/:id"
            element={<FormFiller fileId={fileId} setfileId={setfileId} />}
          />

          <Route path="/form-confirmation" element={<ConfirmationPage />} />
          <Route path="/" element={<Qrcode />} />
          <Route
            path="/dashboard"
            element={<Dashboard setviewpdf={setviewpdf} />}
          />
          <Route path="/reimburishmentform" element={<ReimbursementForm />} />
          <Route
            path="/reimburishmentdashboard"
            element={<ReimbursementDashboard setviewpdf={setviewpdf} />}
          />
          <Route path="/viewpdf" element={<Example file_id={viewpdf} />} />
          <Route path="/login" element={<Login />} />
          <Route path="/newpatient/:doctorId" element={<NewPatient />} />
          <Route path="/register" element={<Register />} />
          <Route path="/googlelogin" element={<Roles />} />
          <Route path="/scanner" element={<Scanner />} />
          <Route
            path="/fileqr/:uuid"
            element={<VerifyFile setviewpdf={setviewpdf} />}
          />
          <Route path="/hospitalqr" element={<Receptionistregistration />} />
          <Route path="/search" element={<SearchBar />} />
          <Route
            path="/pdf/:doctorId"
            element={
              <Pdf qrUrl={qrUrl} formData={formData} setviewpdf={setviewpdf} />
            }
          />
          <Route
            element={<ProtectedRoute allowedRoles={["Doctor"]} />} // Protect Doctor routes
          >
            <Route
              path="/handwritten"
              element={
                <Doctorform
                  setqrUrl={setQrUrl}
                  setstamp={setstamp}
                  pageNames={pageNames}
                  setpageNames={setpageNames}
                  stamp={stamp}
                  qrUrl={qrUrl}
                  setnextpatient={setnextpatient}
                  forms={forms}
                  setForms={setForms}
                  setaudio={setaudio}
                  formData={formData}
                  setFormData={setFormData}
                  isMounted={isMounted}
                  setIsMounted={setIsMounted}
                  checkedTemplates={checkedTemplates}
                  setCheckedTemplates={setCheckedTemplates}
                  buttonRef={buttonRef}
                  url_id={url_id}
                  seturl_id={seturl_id}
                  uuid={uuid}
                  setUuid={setUuid}
                  setQrCodeUrl={setQrCodeUrl}
                  qrCodeUrl={qrCodeUrl}
                />
              }
            />

            <Route
              path="/history"
              element={
                <Doctorsform
                  nextpatient={nextpatient}
                  setnextpatient={setnextpatient}
                  fix={fix}
                  setfix={setfix}
                  forms={forms}
                  setForms={setForms}
                  formData={formData}
                  setFormData={setFormData}
                />
              }
            />

            <Route
              path="/reception"
              element={
                <Receptionist
                  forms={forms}
                  setForms={setForms}
                  formData={formData}
                  setFormData={setFormData}
                  setviewpdf={setviewpdf}
                  setfileId={setfileId}
                />
              }
            />
            <Route
              path="/formcreation"
              element={
                <FormCreation formData={formData} setviewpdf={setviewpdf} />
              }
            />
            <Route
              path="/doctorsidereport/:patient_id"
              element={<Doctorsidereport setviewpdf={setviewpdf} />}
            />
            <Route
              path="/doctorsidefile/:patient_id"
              element={<Doctorsiderfile />}
            />
          </Route>
          <Route
            element={<ProtectedRoute allowedRoles={["NonMedical"]} />} // Protect NonMedical routes
          >
            <Route path="/upload" element={<Upload />} />
            <Route
              path="/report-file"
              element={<Report setviewpdf={setviewpdf} />}
            />
          </Route>
          <Route
            element={<ProtectedRoute allowedRoles={["Hospital"]} />} // Protect  hostpital routes
          >
            <Route path="/billing" element={<Billing />} />
            <Route
              path="/hospitalreception"
              element={<Hospital setviewpdf={setviewpdf} />}
            />
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
