import { useState, useEffect } from 'react';
import axiosInstance from '../axiosInterceptor';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function SearchBar() {
    const [profession, setProfession] = useState('Doctor');
    const [name, setName] = useState('');
    const [users, setUsers] = useState([]);
    const [noMatch, setNoMatch] = useState(false);
    const { t } = useTranslation();
    const navigate = useNavigate();

    const fetchUsers = async () => {
        const response = await axiosInstance.get(`/api/form/search`, {
            params: {
                profession,
                name,
            },
        });
        setUsers(response.data);
        console.log(response.data)
        setNoMatch(response.data.length === 0);
    };

    useEffect(() => {
        fetchUsers(); // Call initially to load all users
    }, []);

    const handleNameChange = (e) => {
        setName(e.target.value);
        fetchUsers();
    };

    const handleform = (id) => {
        navigate(`/newpatient/${id}`);
    };

    const getInitials = (fullName) => {
        const nameArray = fullName.split(' ');
        const initials = nameArray.map(name => name[0]).join('');
        return initials;
    };


    return (
        <div className="max-w-lg mx-auto p-4 pt-6 md:p-6 lg:p-12">
            <h2 className="text-2xl font-bold mb-4">{t('search_for_doctor')}</h2>
            <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                    <input
                        type="text"
                        value={name}
                        onChange={handleNameChange}
                        placeholder={t('history.name')}
                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white"
                    />
                </div>
                <div className="w-full md:w-1/2 px-3">
                    <button
                        onClick={fetchUsers}
                        className="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded"
                    >
                        {t('search')}
                    </button>
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {users.map((user) => (
                    <div
                        key={user._id}
                        className="border border-gray-300 p-4 rounded-md shadow hover:bg-gray-100 cursor-pointer transition duration-200 ease-in-out"
                        onClick={() => handleform(user._id)}
                    >
                        <div className="w-16 h-16 flex items-center justify-center bg-gray-500 text-white rounded-full mb-3 text-lg font-semibold">
                            {getInitials(user.name)}
                        </div>
                        <h3 className="text-md font-semibold">{user.name}</h3>
                        <p className="text-sm text-gray-500">{user.position}</p>
                        <p className="text-sm text-gray-500">{user?.subProfession?.department}</p>
                        <p className="text-sm">{user?.subProfession?.hospital}</p>
                    </div>
                ))}
                {noMatch && (
                    <div className="col-span-full text-center text-gray-500">
                        No matches found
                    </div>
                )}
            </div>
        </div>
    );
}

export default SearchBar;
