import React, { useEffect, useState } from 'react';
const PDFJS = require("pdfjs-dist/webpack");

const PdfToImage = ({ pdfUrl }) => {
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        const loadPdf = async () => {
            const loadingTask = PDFJS.getDocument(pdfUrl);
            const pdf = await loadingTask.promise;
            const page = await pdf.getPage(1);

            const scale = 1.5;
            const viewport = page.getViewport({ scale });

            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            const renderContext = {
                canvasContext: context,
                viewport: viewport,
            };

            await page.render(renderContext).promise;

            const imgDataUrl = canvas.toDataURL('image/png');
            setImageUrl(imgDataUrl);
        };

        loadPdf();
    }, [pdfUrl]);

    return (
        <span>
            {imageUrl ? (

                <img src={imageUrl} alt="PDF Page as Image" className="h-[180px] w-[180px]" />

            ) : (
                'Loading...'
            )}
        </span>
    );
};

export default PdfToImage;
